import * as React from 'react';
import { Component, ReactNode } from 'react';
import {
  focusAdjacentElement, renderSpam, 
  setStatePromiseAtomic, uniqueId
} from '../shared/ui';

export interface HorizontalTab {
  key?: string;
  titleText: string;
  titleRender?: ReactNode | ((text: string) => ReactNode);
  icon: ReactNode;
  className?: string;
  visible?: boolean;
  unloadDisabled?: boolean | (() => boolean);
  unloadImmediate?: boolean | (() => boolean);
  body: ReactNode | ((tab: HorizontalTab, visible: boolean) => ReactNode);
}

export interface HorizontalTabContainerProps {
  storageKey?: string;
  tabs: HorizontalTab[];
  containerClassName?: string;
  tabContainerClassName?: string;
  tabClassName?: string;
  invisibleTabsDisappear?: boolean;
  lazy?: boolean;
  unloadTimeoutMs?: number;
  enableNav?: boolean;
  className?: string;
  vertical?: boolean;
  tabChanged?: (tabIndex: number) => void;
  tabClicked?: (tabIndex: number) => void;
  forceTabIndex?: number | null;
}

interface HorizontalTabContainerState {
  selectedTabIndex: number;
  tabSelectionHistory: Set<number>;// = new Set<number>();
}

export class HorizontalTabContainer
    extends Component<HorizontalTabContainerProps,
      HorizontalTabContainerState> {
  private uniqueId: string;
  private unloadTimeouts: NodeJS.Timeout[] = [];

  constructor(props: HorizontalTabContainerProps) {
    super(props);

    let initialTabIndex = this.loadTabIndex(0);
    this.state = {
      selectedTabIndex: initialTabIndex,
      tabSelectionHistory: new Set<number>([initialTabIndex])
    };

    this.uniqueId = uniqueId();
  }

  shouldComponentUpdate(
      nextProps: HorizontalTabContainerProps, 
      nextState: HorizontalTabContainerState): boolean {
    // Must return true, or it won't update children,
    // because react is broken, a bit
    return true;
  }

  private isUnloadDisabled(tabIndex: number): boolean {
    let unload = this.props.tabs[tabIndex].unloadDisabled;
    if (typeof unload === 'function')
      return unload();
    return unload;
  }

  private isUnloadImmediate(tabIndex: number): boolean {
    let unload = this.props.tabs[tabIndex].unloadImmediate;
    if (typeof unload === 'function')
      return unload();
    return unload;
  }

  componentDidUpdate(
      prevProps: HorizontalTabContainerProps,
      prevState: HorizontalTabContainerState): void {
    let oldTabIndex: number = prevState.selectedTabIndex;
    let newTabIndex: number = this.state.selectedTabIndex;
    if (oldTabIndex !== newTabIndex) {
      if (this.props.tabChanged)
        this.props.tabChanged(newTabIndex);

      if (this.props.unloadTimeoutMs && !this.isUnloadDisabled(oldTabIndex)) {
        // Make sure any active timeout on the incoming tab is cleared
        if (this.unloadTimeouts[newTabIndex]) {
          clearTimeout(this.unloadTimeouts[newTabIndex]);
          console.log('cleared unload timeout for incoming tab', newTabIndex);
          this.unloadTimeouts[newTabIndex] = null;
        }

        // Start a timeout on the outgoing tab
        let timeout = setTimeout((oldTabIndex) => {
          this.setState((prevState) => {
            console.log('got timeout to unload tab', oldTabIndex);
            this.unloadTimeouts[oldTabIndex] = null;
            return this.unloadTabPartial(oldTabIndex, prevState) as
              HorizontalTabContainerState;
          });
        }, this.isUnloadImmediate(oldTabIndex) ? 1 :
          this.props.unloadTimeoutMs, oldTabIndex);

        this.unloadTimeouts[oldTabIndex] = timeout;

        console.log('scheduled to autounload tab', oldTabIndex);
      }

      this.setState((prevState) => {
        return this.loadTabPartial(newTabIndex, prevState) as
          HorizontalTabContainerState;
      });
    }

    if (typeof this.props.forceTabIndex === 'number' &&
        this.props.forceTabIndex !== this.state.selectedTabIndex &&
        //prevProps.forceTabIndex !== this.props.forceTabIndex &&
        this.props.forceTabIndex < (this.props.tabs?.length ?? 0) &&
        this.props.forceTabIndex >= 0) {
      console.log('Forcing tab index to ', this.props.forceTabIndex);
      this.setState({
        selectedTabIndex: this.props.forceTabIndex
      });
    }

    if (this.state.selectedTabIndex >= (this.props.tabs?.length || 1)) {
      console.log('fixing up out of range tab index');
      this.setState({
        selectedTabIndex: (this.props.tabs?.length || 0) - 1
      });
    }

    // if (prevState.selectedTabIndex !== this.state.selectedTabIndex) {
    //   let slug = this.props.tabs[this.state.selectedTabIndex].key;
    //   if (slug)
    //     history.pushState(null, '', '/' + slug);
    // }
  }

  public tabClassAt(index: number, ...otherClasses: string[]): string {
    return (this.state.selectedTabIndex === index 
        ? 'active-tab' : 'inactive-tab') +
      ' ' + otherClasses.join(' ');
  }

  public render(): JSX.Element {
    renderSpam('HorizontalTabContainer');
    if (this.props.vertical)
      return this.renderVertical();
    return <>
      <div 
          className={
            "tab-control-container " + (this.props.containerClassName || '')
          }
          >
        <div className={"tab-control-tabs " + 
            (this.props.tabContainerClassName || '')}
            role="tablist">{
          this.props.tabs?.filter((tab) => {
            return tab.visible ?? true;
          }).map((tab, index) => {
            return <div 
            tabIndex={index === this.state.selectedTabIndex ? 0 : -1}
            role="tab"
            aria-expanded={index === this.state.selectedTabIndex}
            aria-controls={this.uniqueId + 'tabpanel_' + index}
            aria-selected={index === this.state.selectedTabIndex}
            onKeyDown={(event) => this.onKeyDownTab(event)}
            className={
              this.tabClassAt(index, 'tab-tab') + ' ' + 
                (this.props.tabClassName || '') + 
                (tab.className || '')
            }
            onClick={(event) => {
              this.tabClick(event, tab, index);
            }}
            key={this.tabKey(tab)}>
            <i>
              {tab.icon}
            </i>
            <span>{renderTabTitle(tab)}</span>
          </div>;
        })}</div>
        <div className="tab-control-body">{
          this.props.tabs?.map((tab, index) => {
            if (this.props.unloadTimeoutMs && 
                !this.state.tabSelectionHistory.has(index))
              return <div key={this.tabKey(tab)} 
                  hidden>
                Unloaded tab panel
              </div>;

            return (!this.props.invisibleTabsDisappear ||
                index === this.state.selectedTabIndex) ||
                (this.props.lazy && this.state.tabSelectionHistory.has(index))
                ? <div 
                      hidden={index !== this.state.selectedTabIndex}
                      id={this.uniqueId + 'tabpanel_' + index}
                      role="tabpanel"                      
                      key={this.tabKey(tab)}
                      className={this.tabClassAt(index, 'tab-body')}>
                    { 
                      typeof tab.body === 'function' 
                        ? tab.body(tab, index === this.state.selectedTabIndex) 
                        : tab.body 
                    }
                  </div>
                : <div></div>;
          })
        }</div>
      </div>
      <nav>
        {
          (this.props.enableNav && this.props.tabs.map((tab, index) => {
            return <a href={
                  '?' + encodeURIComponent(this.tabKey(tab) || 'button')
                }
                key={this.tabKey(tab)} 
                className={this.tabClassAt(index, 'nav-item')}
                onClick={(event) => this.tabClick(event, tab, index)}>
              {tab.icon}
              <span>{renderTabTitle(tab)}</span>
            </a>;
          })) || null
        }
      </nav>
    </>;
  }
  
  private renderVertical(): JSX.Element {
    return (
      <div className="vertical-nav">
        {this.props.tabs.map((tab, index) => {
          return (
            <div key={tab.key || ('tab' + index)}
              onClick={(event) => this.tabClick(event, tab, index)}>
              {tab.icon} {renderTabTitle(tab)}
            </div>
          );
        })}
      </div>
    );
  }

  private tabKey(tab: HorizontalTab): string {
    return tab.key || 
      (typeof tab.titleText === 'string' && tab.titleText) || 
      null;
  }

  private onKeyDownTab(event: React.KeyboardEvent<HTMLDivElement>): void {
    let newTabIndex: number = -1;
    switch (event.code) {
      case 'ArrowRight':
        newTabIndex = this.state.selectedTabIndex + 1 < this.props.tabs.length
          ? this.state.selectedTabIndex + 1
          : 0;
        break;
        
      case 'ArrowLeft':
        newTabIndex = this.state.selectedTabIndex > 0
          ? this.state.selectedTabIndex - 1
          : this.props.tabs.length - 1;
        break;
      
      case 'ArrowDown':
        event.preventDefault();
        focusAdjacentElement();
        return;
      
      case 'Home':
        newTabIndex = 0;
        break;

      case 'End':
        newTabIndex = this.props.tabs.length - 1;
        break;
      
      default:
        return;
    }

    if (newTabIndex !== this.state.selectedTabIndex) {
      this.setState({
        selectedTabIndex: newTabIndex
      });
    }
  }

  private saveTabIndex(index: number): void {
    if (!this.props.storageKey)
      return;
    
    let indexString = '' + index;
    console.assert(this.props.storageKey);
    localStorage.setItem(this.props.storageKey, indexString);
    sessionStorage.setItem(this.props.storageKey, indexString);
  }

  private loadTabIndex(defaultIndex?: number): number {
    if (!this.props.storageKey)
      return defaultIndex;
    let storageValue = sessionStorage.getItem(this.props.storageKey);
    if (storageValue === null)
      storageValue = localStorage.getItem(this.props.storageKey);
    if (storageValue === null)
      return defaultIndex || 0;
    return +storageValue;
  }

  public tabClick(event: React.MouseEvent<HTMLElement>,
      tab: HorizontalTab, index: number): void {
    event.preventDefault();    
    this.setTabIndex(index)
    .then((result) => {
      if (this.props.tabClicked)
        this.props.tabClicked(index);
      return result;
    });
  }

  public setTabIndex(index: number): Promise<void> {
    this.saveTabIndex(index);

    return setStatePromiseAtomic<HorizontalTabContainerState, 
        HorizontalTabContainer>(this, (prevState) => {
      return {
        selectedTabIndex: index
      } as HorizontalTabContainerState;
    });
  }

  private loadTabPartial(indexToLoad: number, 
      prevState: Readonly<HorizontalTabContainerState>)
      : Partial<HorizontalTabContainerState> {
    if (this.unloadTimeouts[indexToLoad])
      clearTimeout(this.unloadTimeouts[indexToLoad]);
    this.unloadTimeouts[indexToLoad] = null;

    let newTabSelectionHistory = new Set<number>(
      prevState.tabSelectionHistory);

    newTabSelectionHistory.add(indexToLoad);

    return {
      tabSelectionHistory: newTabSelectionHistory
    };
  }

  private unloadTabPartial(indexToUnload: number,
      prevState: Readonly<HorizontalTabContainerState>)
    : Partial<HorizontalTabContainerState> {
    this.unloadTimeouts[indexToUnload] = null;

    let newTabSelectionHistory = new Set<number>(
      prevState.tabSelectionHistory);

    newTabSelectionHistory.delete(indexToUnload);

    return {
      tabSelectionHistory: newTabSelectionHistory
    };
  }
}

export function renderTabTitle(tab: HorizontalTab): ReactNode {
  if (typeof tab.titleRender === 'function')
    return tab.titleRender(tab.titleText);
  return tab.titleText;
}
