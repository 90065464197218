import * as React from 'react';
import { ModalEditPermissions } from './modals/ModalEditPermissions';
import { 
  PermissionUser, 
  User, getUsersByOrganization,
  deleteUserById, Organization, 
  getNotificationTriggers, patchUserById,
  SiteDevices, getAllSiteDevices
} from '../api';
import { 
  HorizontalTab, HorizontalTabContainer
} from './modals/HorizontalTabContainer';
import { ModalEditUser } from './modals/ModalEditUser';
import { OrganizationChangeListener } from '../App';
import { ModalConfirm } from './modals/ModalConfirm';
import { ModalFactory } from './modals/Modal';
import {
  isDemo,
  renderSpam, setStatePromise, setStatePromiseAtomic,
  toastError, toastSuccess
} from './shared/ui';
import { PureComponent, ReactNode } from 'react';
import { SwychedSpinner } from './spinner.component';
import { AppNotificationTrigger } from '../api/notification';
import { FormSwitch } from './FormSwitch';
import './SheetUsers.css';

import { CognitoService } from "./services/cognito.service";
import { xl8 } from '../translations/i18n';
import { PlusIcon } from './icons/PlusIcon';
import { RemoveUserIcon } from './icons/RemoveUserIcon';


interface UsersSheetState {
  organization: Organization
  users: User[] | null;
  selectedUser: User | null;
  originalUsers: User[] | null;
  originalLookup: Map<number, User>;
  changedCount: number;
  saveBusy: boolean;
  triggers: AppNotificationTrigger[];
  siteDevices: SiteDevices;
}

export interface UsersSheetProps {
  organization: Organization;
  visible: boolean;
}

export class UsersSheet 
    extends PureComponent<UsersSheetProps, UsersSheetState> 
    implements OrganizationChangeListener {
  private notificationTriggers: Promise<AppNotificationTrigger[]>;

  private cognitoService = CognitoService.getInstance();

  constructor(props: UsersSheetProps) {
    super(props);

    this.state = {
      organization: props.organization,
      users: null,
      originalUsers: null,
      selectedUser: null,
      originalLookup: null,
      changedCount: 0,
      saveBusy: false,
      triggers: null,
      siteDevices: null
    };
  }

  componentDidUpdate(
      oldProps: UsersSheetProps, 
      oldState: UsersSheetState): void {
    if (this.props.organization?.id !== oldProps.organization?.id) {
      console.log('organization changed by props');
      this.setState({
        organization: this.props.organization
      });
    }

    if (oldState.organization !== this.state.organization)
      this.handleOrganizationChange();
    
    // If transition away from zero
    if (!oldState.changedCount && !!this.state.changedCount)
      this.hookUnload();

    // If transition to zero
    if (!!oldState.changedCount && !this.state.changedCount)
      this.unhookUnload();
    
    if (oldState.users !== this.state.users) {
      this.setState({
        changedCount: this.countChangedUsersFrom(
            this.state.originalUsers, this.state.users)
      });
    }
  }

  private handleOrganizationChange(): Promise<void[]> {
    return Promise.all([
      this.updateUserList(),
      this.updateTriggerList(),
      this.updateDeviceList()
    ]);
  }

  private updateTriggerList(): Promise<void> {
    return Promise.resolve().then(() => {
      return getNotificationTriggers(this.state.organization.id);
    }).then((triggers) => {
      this.setState({
        triggers: triggers
      });
    }).catch((err) => {
      this.setState({
        triggers: null
      });
      toastError('Failed getting triggers: ' + err.message);
    });
  }

  private updateDeviceList(): Promise<void> {
    return Promise.resolve().then(() => {
      return getAllSiteDevices(this.state.organization.id);
    }).then((siteDevices) => {
      this.setState({
        siteDevices: siteDevices
      });
    }).catch((err) => {
      this.setState({
        siteDevices: null
      });
      toastError('Failed getting device list: ' + err.message);
    });
  }

  updateUserList(): Promise<void> {
    console.assert(this.state.organization, 'No organization!');
    if (this.state.organization) {
      let organizationId = this.state.organization.id;
      return Promise.resolve().then(() => {
        return getUsersByOrganization(organizationId);
      }).then((users) => {
        let originalUsers = users.map((user) => ({ ...user }));
        return setStatePromiseAtomic<UsersSheetState, UsersSheet>(this,
          (prevState) => {
            let oldSelected = prevState.selectedUser?.id || null;
            let newSelected = users.find((user) => {
              return user.id === oldSelected;
            }) || null;
            let result: Partial<UsersSheetState> = {
              users: users,
              originalUsers: originalUsers,
              originalLookup: this.buildOriginalLookup(originalUsers),
              selectedUser: newSelected || users[0] || null
            };
            return result;
          });
      }).catch((err) => {
        this.setState({
          users: null,
          originalUsers: null,
          changedCount: 0,
          selectedUser: null,
          originalLookup: null
        });
        toastError('Failed getting users');
      });
    } else {
      console.log('no organization id?!');
      return Promise.resolve();
    }
  }

  
  componentDidMount(): void {
    if (this.state.organization)
      this.handleOrganizationChange();
  }
  
  private beforeUnloadHandler: ((event: Event) => string) | null = null;

  private hookUnload(): void {
    console.assert(!this.beforeUnloadHandler);
    this.beforeUnloadHandler = (event) => {
      event.preventDefault();
      (event as any).returnValue = 'There are unsaved user changes';
      return 'There are unsaved user changes';
    };
    console.log('hooking unload');
    window.addEventListener('beforeunload', this.beforeUnloadHandler);
  }

  private unhookUnload(): void {
    console.assert(!!this.beforeUnloadHandler);
    console.log('unhooking unload');
    window.removeEventListener('beforeunload', this.beforeUnloadHandler);
    this.beforeUnloadHandler = null;
  }

  organizationChanged(org: Organization): Promise<void> {
    return setStatePromise<UsersSheetProps, UsersSheet>(this, {
      organization: org
    }).then(() => {
      return this.updateUserList();
    });
  }

  buildOriginalLookup(originalUsers: User[]): Map<number, User> {
    return originalUsers.reduce((originalLookup, user) => {
      originalLookup.set(user.id, user);
      return originalLookup;
    }, new Map<number, User>());
  }

  userChangeHandler<T>(selectedUser: User, key: keyof User, value: T): void {
    console.assert(selectedUser);
    this.setState((prevState) => {
      // Clone user list
      let newUsers = prevState.users.slice();
      // Remember selected
      let index = newUsers.indexOf(selectedUser);
      if (index < 0)
        return null;
      
      // Replace the object being modified with a clone
      let newUser = {
        ...newUsers[index],
        [key]: value
      };

      // Replace the user list entry with the clone, discard old one
      newUsers[index] = newUser;

      return {
        users: newUsers,
        selectedUser: newUsers[index]
      };
    });
  }

  countChangedUsersFrom(originalUsers: User[], newUsers: User[]): number {
    return originalUsers.reduce((total, user, userIndex) => {
      let newUser = newUsers[userIndex];
      return total + +this.userChanged(newUser);
    }, 0);
  }
  

  onDeleteUser(user: User): void {
    let organizationId = this.state.organization.id;

    ModalFactory.withDialog(ModalConfirm, (confirmModal) => {
      return confirmModal.showDialog(
          'Are you sure you want to delete ' + user.email + '?',
          'Delete User', 'Delete User')
      .then((result) => {
        if (!result || !result.ok)
          return;
        
        deleteUserById(organizationId, user.id).then(() => {
          toastSuccess(xl8('deleteUserSuccess', user.email));
          this.updateUserList();
        }).catch((err) => {
          toastError(xl8('deleteUserFail', err.message));
        });
      });
    });
  }

  lazyNotificationTriggers(): Promise<AppNotificationTrigger[]> {
    if (!this.notificationTriggers)
      this.notificationTriggers = getNotificationTriggers(
        this.state.organization.id);
    return this.notificationTriggers;
  }

  onAddUser(): Promise<User | null> {
    return ModalFactory.withDialog(ModalEditUser, (editUserModal) => {
      return editUserModal.showDialog(this.state.organization.id, 
        this.lazyNotificationTriggers(), null, xl8('addUser'), xl8('addUser'))
      .then((result) => {
        if (!result)
          return null;
        
        this.updateUserList();
        toastSuccess('Created New User "' + result.user.email + '"');
        return result.user;
      }).catch((err) => {
        toastError(err.message);
        return null;
      });
    });
  }
  // MW Wip
  // onResetUserPassword(user: User): void {
  //   ModalFactory.withDialog(ModalConfirm, (confirmModal) => {
  //     return confirmModal.showDialog(
  //         'Are you sure you want to reset user ' + user.email + '\'s password?',
  //         'Reset Password', 'Reset Password')
  //     .then((result) => {
  //       if (!result || !result.ok)
  //         return;
        
  //       this.cognitoService.resetPassword(user.email).then(() => {
  //         toastSuccess('User "' + user.email + '" Reset code sent');
  //       }).catch((err) => {
  //         toastError('Error deleting User: ' + err.message);
  //       });
  //     });
  //   });
  // }

  private tabs: HorizontalTab[] = [{
    titleText: xl8('account'),
    icon: '',
    className: 'user-management-account-tab',
    body: (tab) => 
      <React.Fragment key={'userInformation'}>
        <div className="col-lg-12 user-account-tab">
          <div>
            <label>
              <span>
                {xl8('firstName')}
              </span>
              <input className="form-control user-name-field"
                  value={this.state.selectedUser?.firstName || ''}
                  disabled={!this.state.selectedUser}
                  onChange={(event) => {
                    this.userChangeHandler(this.state.selectedUser, 
                      'firstName', event.target.value);
                  }}/>
            </label>
            <label>
              <span>
                {xl8('lastName')}
              </span>
              <input className="form-control user-name-field"
                  value={this.state.selectedUser?.lastName || ''}
                  disabled={!this.state.selectedUser}
                  onChange={(event) => {
                    this.userChangeHandler(this.state.selectedUser, 
                      'lastName', event.target.value);
                  }}/>
            </label>
          </div>
          <div>
            <label>
              <span>
                {xl8('emailAddress')}
              </span>
              <input className="form-control"
                  disabled={!this.state.selectedUser}
                  value={this.state.selectedUser?.email || ''}
                  onChange={(event) => {
                    this.userChangeHandler(this.state.selectedUser, 
                      'email', event.target.value);
                  }}/>
            </label>
          </div>
          <div>
            <label>
              <span>
                {xl8('role')}
              </span>
              <select className="form-select"
                  disabled={!this.state.selectedUser}
                  value={this.state.selectedUser?.role || ''}
                  onChange={(event) => {
                    this.userChangeHandler(this.state.selectedUser, 
                      'role', event.target.value);
                  }}
                  name="role">
                <option value="admin">Admin</option>
                <option value="readonly">Readonly</option>
              </select>
            </label>
          </div>
          {/* <div>
            <label>
              <span>Password</span>
              <div className="text-link">
                <button
                  // onClick={(event) => 
                  //   this.onResetUserPassword(this.state.selectedUser)}  
                  >
                  Send password reset
                </button>
              </div>
            </label>
          </div> */}
          {/* <h5>Account Type</h5>
          <label className="form-check-label" key="1">
            <input className="form-check-input"                      
              type="radio" name="localAccount"
              checked={true}
              value=""
              onChange={(event) => {
              }}
              />
              Local Account
              <div>
                Local users can only access this organization
              </div>
          </label>
          <label className="form-check-label" key="2">
            <input className="form-check-input"                      
              type="radio" name="localAccount"
              checked={true}
              value=""
              onChange={(event) => {
              }}
              />
              Global Account
              <div>
                Global users can access other organizations within the folder “UBC”
              </div>
          </label>
          <label className="form-check-label" key="3">
            <input className="form-check-input"                      
              type="radio" name="localAccount"
              checked={true}
              value=""
              onChange={(event) => {
              }}
              />
              Super Admin Account
              <div>
                Super admins can access all organizations within the folder and manage the organization structure
              </div>
          </label> */}
          <div>
          <label>
            {/* <span>Remove User</span> */}
            <div className="text-link warning-link">
              <button
                onClick={(event) => {
                  this.onDeleteUser(this.state.selectedUser);
                }}
                >
                <span>
                  <RemoveUserIcon width="24" height="24" fill="#e23230" />
                </span>

                {xl8('removeUser')}
              </button>
            </div>
          </label>
        </div>
        </div>
      </React.Fragment>
    },
    // {
    //   title: 'Privileges',
    //   icon: '',
    //   className: '',
    //   body: (tab) => 
    //     <React.Fragment key={'Privileges'}>
    //       <table className="table table-sorter card-table">
    //         <thead className="text-primary">
    //           <tr>
    //             <th scope="col">
    //               Page
    //             </th>
    //             <th scope="col">
    //               No Access
    //             </th>
    //             <th scope="col">
    //               Read Only
    //             </th>
    //             <th scope="col">
    //               Admin
    //             </th>
    //           </tr>
    //         </thead>
    //         <tbody>
    //           {/* {this.renderAccessCards()} */}
    //         </tbody>
    //       </table> 
    //     </React.Fragment>
    //   }, 
    {
      titleText: xl8('userNotifications'),
      titleRender: (text) => 
        <span className="user-notification-header-title">{text} 
          {/* <span className="beta-tag">{xl8('beta')}</span> */}
        </span>,
      icon: '',
      body: (tab) =>
        <React.Fragment key={'userNotifications'}>
          <div className="notification-destination-config">
            <label>
              <input type="checkbox" 
                className="form-check-input big-check" disabled
                checked={this.state.selectedUser?.notifyEmail || null}
                onChange={(event) => {
                  this.userChangeHandler(this.state.selectedUser, 
                      'notifyEmail', event.target.checked);
                }}/>
              {xl8('allowEmailNotifications')}
              <span className="beta-tag" hidden={isDemo()}>
                {xl8('comingSoon')}
              </span>
              <label className="label-detail">
                {xl8('emailsWillBeSentTo')}&nbsp;&nbsp; 
                <input className="user-email-input"
                  value={this.state.selectedUser?.email || null}
                  onChange={(event) => {
                    this.userChangeHandler(this.state.selectedUser,
                        'email', event.target.value);
                  }}/>
                
              </label>
              
              {/* <span className="material-icons">
                edit
              </span> */}
            </label>

            <label>
              <input type="checkbox" 
              className="form-check-input big-check"
              // defaultChecked={true}
              disabled
              onChange={(event) => {
              }}/>
              Allow SMS notifications
              <span className="beta-tag" hidden={isDemo()}>
                {xl8('comingSoon')}
              </span>
              <label className="label-detail">
                Text messages will be sent to&nbsp;&nbsp; 
                <input className="user-phone-input"
                 />
              </label>

            </label>
            <label>
              <input type="checkbox" 
              className="form-check-input big-check"
              defaultChecked={true}
              onChange={(event) => {
              }}/>
              {xl8('allowBrowserNotifications')}
              <span className="label-detail">
                {xl8('receiveAppNotifications')}
              </span>
            </label>
          </div>
          <h4>
            {xl8('getNotificationsForTheseEvents')}
          </h4>
          {
            this.state.triggers?.map((trigger) => {
              return (
                <FormSwitch 
                  key={trigger.id}
                  disabled={!this.state.selectedUser}
                  label={trigger.description}
                  value={this.state.selectedUser?.notificationSubs.includes(
                    trigger.id)}
                  onChange={(newValue) => {
                    let user = this.state.selectedUser;
                    if (!user)
                      return;
                    let index = user.notificationSubs.indexOf(trigger.id);
                    let newSubs = user.notificationSubs.slice();
                    if (newValue)
                      newSubs.push(trigger.id);
                    else
                      newSubs.splice(index, 1);
                    this.userChangeHandler(user, 'notificationSubs', newSubs);
                  }}/>
              );
            })
          }

          <h4>
            {xl8('getNotificationsFromTheseSites')}
          </h4>
            <FormSwitch 
                value={                
                  !this.state.selectedUser?.notificationSites.length ||
                  this.state.siteDevices?.sites.length === 
                  this.state.selectedUser?.notificationSites.length
                }
                label={xl8('allSites')}
                disabled={true}/>                            
            {this.state.siteDevices?.sites.map((site) => {
              let checked = this.state.selectedUser
                ?.notificationSites.includes(site.id);
              return (
                <FormSwitch 
                  key={site.id}
                  label={site.name}
                  value={checked}
                  onChange={(newValue) => {
                    let newSites = this.state.selectedUser
                      ?.notificationSites.slice() || [];
                    let index = newSites.indexOf(site.id);
                    if (newValue && index < 0)
                      newSites.push(site.id);
                    else if (!newValue && index >= 0)
                      newSites.splice(index, 1);
                    this.userChangeHandler(this.state.selectedUser,
                      'notificationSites', newSites);
                  }}
                  />
              );
            }) || <SwychedSpinner busy={1}/>
          }
        </React.Fragment>
    }
  ];
  

  render(): JSX.Element {
    if (!this.props.visible)
      return <div>Hidden tab panel</div>;
    renderSpam('UsersSheet');

    
    return (
      <>
      <div className="row sheet-header">
        <div className="page-title-container col-lg-9">
          <h2 className="sheet-title">
            {xl8('users')}
          </h2>
          <div className="sheet-subtitle">
            {xl8('manageUsersAndNotificationSettings')}
          </div>
        </div>
        <div className="sheet-control-container col-lg-3">
          <button className="btn btn-primary 
            sidebar-primary-btn pull-right"
              type="button" 
              onClick={(_) => this.onAddUser()}>
            <PlusIcon width="21" height="21" fill="#fff"/>
            {xl8('addUser')}
          </button>
        </div>
      </div>
        <div className="row user-management clear">
          <div className="col-lg-3 col-md-3">
            <div className="user-select">
              {this.renderUserSelect()}
            </div>

            <div className="left-sidebar-list">
              {this.renderUserList()}
            </div>
          </div>
          <div className="col-lg-9 col-md-9">
            <div className="card">
              <div className="card-body no-top-padding">
                {this.renderUserEditor()}
                <div className="col-lg-4">
                  <button className="btn btn-primary pull-right user-save-all-btn"
                    type="button" 
                    hidden={!this.state.changedCount}
                    disabled={this.state.saveBusy}
                    onClick={(_) => this.onSaveAll()}>
                    <i className="material-icons">save</i>
                    Save All
                    <SwychedSpinner busy={+this.state.saveBusy}/>
                  </button>
                  <button className="btn btn-primary pull-right"
                      type="button" 
                      hidden={!this.userChanged(this.state.selectedUser)}
                      disabled={this.state.saveBusy}
                      onClick={(_) => this.onRevert()}>
                    <i className="material-icons">undo</i>
                    Revert User
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  
  onRevert(): void {
    this.setState((prevState) => {
      let origUser = this.state.originalLookup.get(prevState.selectedUser.id);
      let index = prevState.users.findIndex((user) => user.id === origUser.id);
      let newUsers = prevState.users.slice();
      newUsers[index] = {
        ...origUser
      };
      return {
        users: newUsers,
        selectedUser: newUsers[index]
      };
    });
  }

  private onSelectUser(user: User) {
    this.setState({
      selectedUser: user
    });
  }

  private formatUserName(user?: User): string | null {
    if (!user)
        return null;
    if (user.firstName || user.lastName)
        return user.firstName + ' ' + user.lastName;

    return '<' + user.email + '>';
  }

  private renderUserSelect(): ReactNode {
    if (!this.state.users) {
      return (
        <div>
          <SwychedSpinner busy={1} />
        </div>
      );
    }
    return (
        <select className="form-select"
            value={this.state.selectedUser?.id}
            onChange={(event) => {
              let user = this.state.users?.find((user) => {
                return user.id === +event.target.value;
              });
              this.onSelectUser(user); 
            }}>

          {
            this.state.users.map((user) => {
              return (
                <option key={user.id} value={user.id}>
                  {user.firstName} {user.lastName}
                </option>
              );
            })
          }
        </select>
    );
  }
  
  private renderUserList(): ReactNode {
    if (!this.state.users) {
      return (
        <div>
          <SwychedSpinner busy={1} />
        </div>
      );
    }

    return (
      <div className="left-side-bar-filter">
        <ul>{
          this.state.users.map((user) => {
            return (
              <li key={user.id}
                  className={this.state.selectedUser === user
                    ? ' selected' : ''} 
                  onClick={(_) => {
                    this.setState({
                      selectedUser: user
                    });
                  }}>
                <span className="sidebar-list-item text-truncate"
                  style={this.userChanged(user)
                    ? { fontWeight: 600 } : {}}> 
                    {this.formatUserName(user)}
                </span>
                {/* <Dropdown
                  className="left-sidebar-control-icon"
                  icon='ellipsis horizontal'>
                  <Dropdown.Menu className="left pointing">
                    <Dropdown.Item icon='delete'
                      text={xl8('deleteUser')}
                      onClick={(event) => {
                        this.onDeleteUser(user);
                      }}/>
                  </Dropdown.Menu>
                </Dropdown> */}
              </li>
            );
          })
          
        }
        </ul>
      </div>
    );
  }

  private renderUserEditor(): ReactNode {
    return (
      <>
        <h2 className="page-title card-body-page-title">
          {this.formatUserName(this.state.selectedUser)}
        </h2>
        <HorizontalTabContainer
          tabs={this.tabs}
          invisibleTabsDisappear={false}
          lazy={true}
          unloadTimeoutMs={10000}
          />
      </>
    );
  }

  private onSaveAll(): void {
    this.setState({
      saveBusy: true
    });

    let changedUsers = this.state.users.filter((user) => {
      return this.userChanged(user);
    });

    // Do all saves in parallel, show toast and use null as response for errors
    let promises: Promise<User>[] = changedUsers.map((changedUser) => {
      return patchUserById(this.state.organization.id, changedUser)
      .catch((err) => {
        toastError('Save user failed: ' + err);
        return null;
      });
    });

    // Attach error handlers

    Promise.all(promises).then((changedUsers: User[]) => {
      // Build lookup table of responses, by id
      let userLookup = new Map<number, User>();
      changedUsers.forEach((changedUser) => {
        // Ignore failed (nulls)
        if (changedUser)
          userLookup.set(changedUser.id, changedUser);
      });

      let failures = changedUsers.reduce((total, user) => {
        return total + +!user;
      }, 0);

      let successes = changedUsers.length - failures;

      if (successes)
        toastSuccess('Saved ' + successes + ' users');
      
      if (failures > 0)
        toastError((failures) + ' users failed update');

      this.setState((prevState) => {
        // Find the selected user before
        let oldSelected = prevState.users.find((user) => {
          return prevState.selectedUser &&
            user.id === prevState.selectedUser.id;
        });

        // Make a new users array
        let replacementUsers = prevState.users.slice();

        replacementUsers.forEach((user, index) => {
          // If this user was saved, replace the user object with response
          let replacement = userLookup.get(user.id);

          if (replacement)
            replacementUsers[index] = replacement;

          // Otherwise leave it alone
        });

        // Make new "originals", since they were saved
        let newOriginalUsers = replacementUsers
          .map((user) => {
            user = {...user};
            user.notificationSubs = user.notificationSubs.slice();
            user.notificationSites = user.notificationSites.slice();
            return user;
          });

        // Find which object is the selected one now
        let newSelectedUser = replacementUsers.find((user) => {
          return oldSelected && user && user.id === oldSelected.id;
        }) || null;

        // Update the display
        return {
          users: replacementUsers,
          originalUsers: newOriginalUsers,
          originalLookup: this.buildOriginalLookup(newOriginalUsers),
          selectedUser: newSelectedUser,
          saveBusy: false
        };
      });
    }).catch((err) => {
      this.setState({
        saveBusy: false
      });
      throw err;
    });
  }
  
  private userChanged(user: User): boolean {
    if (!user)
      return false;

    let oldUser = this.state.originalLookup.get(user.id);

    let changed: boolean = Object.keys(user).some((key) => {
      let value = user[key];
      let oldValue = oldUser[key];
      
      // Fastpath properties that are not arrays
      if (!Array.isArray(value))
        return value !== oldValue;
      
      //
      // Array
        
      console.assert(Array.isArray(oldValue));
      
      if (value.length !== oldValue.length)
        return true;
      
      let oldSet = new Set<number>(oldValue);
      return !value.every((entry) => oldSet.has(entry));
    });

    if (changed)
      console.log('user changed to', user, 'from', oldUser);

    return changed;
  }

  private onEditPermissions(user: PermissionUser): Promise<boolean> {
    return ModalFactory.withDialog(ModalEditPermissions, 
    (editPermissionsModal) => {
      return editPermissionsModal.showDialog(user)
      .then((result) => {
        if (!result || !result.name)
          return null;
  
        toastSuccess('Edited Permissions "' + result.name + '"');
  
        return true;
      });
    });
  }

  // private showCreateUserDialog(organizationId: number): Promise<boolean> {
  //   return ModalFactory.withDialog(ModalEditUser, (editUserModal) => {
  //     return editUserModal.showDialog(this.organizationId, 
  //       null, 'Add User', 'Confirm')
  //     .then((result) => {
  //       if (!result || !result.user)
  //         return null;
  
  //       return true;
        
  //     });
  //   });
  // }

  onEditUser(user: User): void {
    ModalFactory.withDialog(ModalEditUser, (editUserModal) => {
      return editUserModal.showDialog(this.state.organization.id, 
        this.lazyNotificationTriggers(), user, xl8('editUser'), xl8('confirm'))
      .then((result) => {
        if (result)
          toastSuccess('User updated: ' + result.user.email);
        this.updateUserList();
      }).catch((err) => {
        toastError('User update failed: ' + err.message);
      });
    });
  }
}

// <button className="btn btn-primary"
// type="button" 
// onClick={(_) => this.onAddUser()}>
// <i className="material-icons">person_add_alt</i>
// Add User
// </button>

// <div className="table-responsive user-table">
// <table className="table tablesorter" id="">
//   <thead className="text-primary">
//     <tr>
//       <th scope="col">
//         Name
//       </th>
//       <th scope="col">
//         Email
//       </th>
//       <th scope="col">
//         Role 
//       </th>
//       <th scope="col">
//         Notification Destination 
//       </th>
//       <th scope="col" className="text-left">
//         User Management
//       </th>
//     </tr>
//   </thead>
// 
//   <tbody>
//     {this.state.users?.map((user) => {
//       return (
//         <tr key={user.id}>
//           <td className="capitalize-name"
//             data-label="Name">{user.firstName}{
//               (user.lastName || user.firstName) ? ' ' : ''
//           }{user.lastName || 'none'} </td>
//           <td data-label="Email">{user.email}</td>
//           <td data-label="Role">
//             <span className={`user-role ${
//                 user.role === 'admin' ? 'admin-tag' : 'viewer-tag'}`}>
//               {user.role}
//             </span>
//           </td>
//           <td data-label="Notification Destination">
//             {user.email}
//           </td>
//           {/* onClick={(_) => this.onEditPermissions(user)} */}
//           <td data-label="User Management">
//             <Dropdown
//               icon='ellipsis horizontal'>
//               <Dropdown.Menu className="left pointing">
//                 <Dropdown.Item icon='edit' text={xl8('editUser')}
//                   onClick={(event) => {
//                     this.onEditUser(user);
//                   }}
//                   />
//                 <Dropdown.Item icon='delete' 
//                   text={xl8('deleteUser')}
//                   onClick={(event) => {
//                     this.onDeleteUser(user)
//                   }} />
//               </Dropdown.Menu>
//             </Dropdown>
//           </td>
//         </tr>
//       );
//     }) || (
//       <tr>
//         <td>
//           <SwychedSpinner busy={1}/>
//         </td>
//       </tr>
//     )}
//   </tbody>
// </table>
// </div>
