
import * as React from 'react';
import { PaymentIntegration, Organization } from '../../api';
import { xl8 } from '../../translations/i18n';
import { PlusIcon } from '../icons/PlusIcon';
import { SearchIcon } from '../icons/SearchIcon';
import { setStatePromise } from '../shared/ui';
import { Modal, ModalBase } from './Modal';
import { ModalFactory } from './Modal';
import { ModalCreateAPIKey } from './ModalApiKey';

export interface ModalPaymentIntegrationProps {
}

export interface ModalPaymentIntegrationResult {
  paymentIntegration: PaymentIntegration;
}

interface ModalPaymentIntegrationState {
  organization: Organization;
}

export class ModalPaymentIntegration
    extends ModalBase<ModalPaymentIntegrationProps,
      ModalPaymentIntegrationResult, ModalPaymentIntegrationState> {
  
  private title: string = 'Create API Key';
  //private okText: string = xl8('cancel');

  constructor(props: ModalPaymentIntegrationProps) {
    super(props);

    this.state = {
      organization: null
    };
  }

  static newPaymentIntegration(organizationId: number): PaymentIntegration {
    return {
      id: 0,
      organizationId: organizationId,
      name: '',
      icon: ''
    };
  }

  public paymentIntegration = [
    {
      "id": "1",
      "name": "UIC Payworld",
      "icon": "uic.svg",
      "description": "Allow users to pay for charging sessions using" +
        " the UIC point-of-sales terminal",
      "apiKey": "d358c018ac8b03e89afa8d"
    },
    {
      "id": "2",
      "name": "Honk Mobile",
      "icon": "honk.svg",
      "description": "Allow users to pay for charging sessions when" +
        " they pay for parking using the Honk mobile app.",
      "apiKey": "d358c018ac8b03e89afa8d"
    },
    {
      "id": "3",
      "name": "PayByPhone",
      "icon": "paybyphone.svg",
      "description": "Allow users to pay for charging sessions with PayByPhone.",
      "apiKey": "d358c018ac8b03e89afa8d"
    },
    {
      "id": "4",
      "name": "PayPal",
      "icon": "paypal.svg",
      "description": "Allow users to pay for charging sessions using PayPal.",
      "apiKey": "d358c018ac8b03e89afa8d"
    }
  ]

  public showDialog(organizationId: number,
      paymentIntegration: PaymentIntegration | null)
      : Promise<ModalPaymentIntegrationResult> {

    this.title = 'Add API Key';
    //this.okText = 'Ok';

    return setStatePromise<ModalPaymentIntegrationState, 
        ModalPaymentIntegration>(this, {})
    .then(() => {
      return this.show();
    });
  }

  render(): JSX.Element {
    const iconImgPath = 'images/';

    return (
      <Modal 
          key="payment integration"
          ref={this.modal}
          title={this.title}
          width="60%"
          // height="740px"
          // onOK={() => this.onOK()}
          confirmButtonContent="close"
          // confirmButtonIcon={'key'}
          >
            <div className="row">
              <div className="col-4">
                {/* <div className="input-group search-filter">
                  <input type="search" className="form-control"
                      placeholder={xl8('search')} aria-label="Search"
                      // value="value"
                      onChange={(event) => {
                      }}
                      aria-describedby="search-addon" />
                  <button className="btn btn-outline"
                      type="button" 
                      onClick={(event) => {

                      }}>
                    <SearchIcon width="20" height="20" fill="#c1c5c8"/>

                  </button>
                </div> */}
              </div>
            </div>
            <div className="row integration-modal">
              { 
                this.paymentIntegration.map((integration) => {
                  return (
                    <div key={integration.id} 
                      className="col-lg-6 col-md-6 col-sm-12">
                      <div className="card">
                        <div className="card-body">
                          <img src={iconImgPath + integration.icon}
                              className="integration-icon"/>
                          <h2 className="integration-header">
                            {integration.name}
                          </h2>
                          <div className="integration-desc">
                            {integration.description}
                          </div>
                          <div className="pull-right">
                            <button className="btn btn-primary create-api-key-btn"
                              onClick={(event) => {
                                this.onAddApiKey();
                              }}>
                              <PlusIcon width="21" height="21" fill="#fff"/>
                              Create API Key
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              }
          </div>
      </Modal>
    );
  }

  onAddApiKey(): void {
    ModalFactory.withDialog(ModalCreateAPIKey, (startSessionModal) => {
      return startSessionModal.showDialog();
    });
  }
  
  private onOK(): Promise<ModalPaymentIntegrationResult> {
    return null;
  }
}
