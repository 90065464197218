import * as React from 'react';
import { Organization, getAccessControlPlansByOrganization, 
  AccessControlGroup, AccessControlPlan,
  AccessControlPlansResponse, deleteAccessControlPlanById, 
  getAccessCardsByOrganization,
  getAllSiteDevices,
  deleteAccessControlGroupById,
  AccessCard,
  deleteAccessCardById,
  AccessControlPlanSync
} from '../api';

import { OrganizationChangeListener } from '../App';
import './SheetAccessControl.css';

import { 
  WizardAuthorizeAccessCard, WizardAuthorizeAccessCardResult} from './modals/WizardAuthorizeAccessCard';
import {
  ModalEditAccessControlPlan
} from './modals/ModalEditAccessControlPlan';
import {
  ModalEditAccessControlGroup
} from './modals/ModalEditAccessControlGroup';
import { ModalEditAccessCard } from './modals/ModalEditAccessCard';
import { ModalConfirm } from './modals/ModalConfirm';
import { Dropdown } from 'semantic-ui-react';
import {
  formatUnixDate, renderSpam, setStatePromise,
  toastError, toastSuccess
} from './shared/ui';
import { PureComponent, ReactNode, RefObject } from 'react';
import { PaginationControl } from './PaginationControl';
import { ModalFactory } from './modals/Modal';
import { SwychedSpinner } from './spinner.component';
import { AccessControlGroupDropdown } from './AccessControlGroupDropdown';
import { xl8 } from '../translations/i18n';
import { AccessControlIcon } from './icons/AccessControlIcon';
import { NoAccessCardsIcon } from './icons/NoAccessCardsIcon';
import { EllipsisIcon } from './icons/EllipsisIcon';
import { PlusIcon } from './icons/PlusIcon';
import { SearchIcon } from './icons/SearchIcon';
import { CloseIcon } from './icons/CloseIcon';
import { CogIcon } from './icons/CogIcon';
import { DeleteIcon } from './icons/DeleteIcon';
import { DisableCardIcon } from './icons/DisableCardIcon';
import { AuthorizeCardIcon } from './icons/AuthorizeCardIcon';

interface AccessControlSheetProps {
  organization: Organization;
  visible: boolean;
}

interface AccessControlSheetState {
  organization: Organization;
  searchTerm: string;
  accessControlPlans: AccessControlPlansResponse;
  accessCards: AccessCard[];
  accessCardPage: number;
  accessCardPageOffset: number;
  accessCardPageCount: number;
  groupFilter: AccessControlGroup;
  sortBy: string;
  sortDir: number;
}

export class AccessControlSheet 
    extends PureComponent<AccessControlSheetProps, AccessControlSheetState>
    implements OrganizationChangeListener {
  private allGroupsElement = React.createRef<HTMLLIElement>();
  private groupElements: RefObject<HTMLLIElement>[] = [];

  constructor(props: AccessControlSheetProps) {
    super(props);

    this.state = {
      organization: props.organization,
      searchTerm: '',
      accessControlPlans: null,
      accessCards: null,
      accessCardPage: 0,
      accessCardPageOffset: 0,
      accessCardPageCount: 0,
      groupFilter: null,
      sortBy: 'date',
      sortDir: -1
    };
  }

  private planSync: AccessControlPlanSync = null;

  componentDidUpdate(
      oldProps: AccessControlSheetProps, 
      oldState: AccessControlSheetState): void {
    if (this.props.organization?.id !== oldProps.organization?.id) {
      console.log('organization changed by props'); //this.updateDevices(false);
      this.setState({
        organization: this.props.organization
      });
    }

    if (oldState.organization !== this.state.organization)
      this.refreshAccessControlPlans(this.state.organization);
  }

  componentDidMount(): void {
    this.planSync = new AccessControlPlanSync((info) => {
      return {
        plans: this.state.accessControlPlans,
        component: this
      };
    });

    if (this.state.organization)
      this.refreshAccessControlPlans(this.state.organization);
  }

  componentWillUnmount(): void {
    this.planSync.detach();
    this.planSync = null;
  }

  organizationChanged(org: Organization): Promise<void> {
    return this.refreshAccessControlPlans(org).then(() => {});
  }
  

  refreshAccessControlPlans(organization: Organization)
      : Promise<AccessControlPlansResponse> {
    let accessControlPlanPromise = 
      getAccessControlPlansByOrganization(organization.id);
    
    let accessControlCardsPromise =
      getAccessCardsByOrganization(organization.id);

    accessControlPlanPromise.then((accessControlPlans) => {
      console.log('Access Control Plan List :', accessControlPlans);

      return setStatePromise<AccessControlSheetState, 
          AccessControlSheet>(this, {
        organization: organization,
        accessControlPlans: accessControlPlans
      });
    }).then(() => {
      return this.state.accessControlPlans;
    });

    accessControlCardsPromise.then((cards) => {
      this.setState({
        accessCards: cards
      });
      return cards;
    });

    return Promise.all([
      accessControlPlanPromise,
      accessControlCardsPromise
    ]).then(([plans, cards]) => {
      return plans;
    });
  }

  onSearchTermChange(searchTerm: string): void {
    setStatePromise<AccessControlSheetState, AccessControlSheet>(this, {
      searchTerm: searchTerm,
      accessCardPage: 0,
      accessCardPageOffset: 0
    });
  }

  render(): JSX.Element {
    if (!this.props.visible)
      return <div>Hidden tab panel</div>;
    
    renderSpam('SheetAccessControl');
    let groupList = this.groupList();

    return (
      <>
        <div className="row sheet-header">
          <div className="page-title-container col-lg-9">
            <h2 className="sheet-title">
              {xl8('accessControl')}
            </h2>
            <div className="sheet-subtitle">
              {xl8('manageAccessCards')}
            </div>
          </div>
          <div className="sheet-control-container col-lg-3">
            <button className="btn btn-primary
              sidebar-primary-btn pull-right"
                type="button" 
                onClick={(event) => this.authorizeRFID()}>
              <PlusIcon  width="21" height="21" fill="#fff"/>
              {xl8('authorizeAccessCard')}
            </button>
          </div>
        </div>
        <div className="row access-control clear">
          <div className="col-lg-3 col-md-4">
            <div className="input-group search-filter">
              <input type="search" className="form-control"
                  placeholder={xl8('searchCards')} aria-label="Search"
                  value={this.state.searchTerm}
                  onChange={(event) => {
                    this.onSearchTermChange(event.target.value);
                  }}
                  aria-describedby="search-addon" />
              <button className="btn btn-outline"
                  type="button" 
                  tabIndex={!this.state.searchTerm ? -1 : 0}
                  onClick={(event) => {
                    this.setState({
                      searchTerm: '',
                      accessCardPage: 0,
                      accessCardPageOffset: 0
                    });
                  }}>
                {
                    !this.state.searchTerm 
                    ? <SearchIcon width="20" height="20" fill="#c1c5c8" />
                    : <CloseIcon width="20" height="20" fill="#c1c5c8" />
                }
              </button>
            </div>
            <div className="access-control-group-select">
              <AccessControlGroupDropdown
                organizationId={this.props.organization?.id}
                groups={groupList}
                all={true}
                value={this.state.groupFilter?.id}
                onChange={(acpgId) => {
                  let group = groupList.find((group) => {
                    return group.id === acpgId;
                  });
                  this.setState({
                    groupFilter: group
                  });
              }}/>
            </div>
            <div className="left-side-bar-filter">
              <ul>
                <li ref={this.allGroupsElement}
                    className={'all-access-card-types' +
                      (!this.state.groupFilter
                      ? ' selected'
                      : '')
                    }
                    onKeyDown={(event) => {
                      this.onGroupKeyDownHandler(
                        event, groupList, null, -1);
                    }}
                    onClick={(event) => {
                      this.setState({
                        groupFilter: null,
                        accessCardPage: 0,
                        accessCardPageOffset: 0
                      });
                    }}
                    tabIndex={0}>
                  {xl8('allGroups')}
                </li>
                <hr className="list-separator"/>
                {this.renderGroupList(groupList)}
                <hr className="list-separator"/>
                <li className="left-sidebar-add-new"
                    onClick={(event) => {
                      this.editAccessControlGroup(null);
                    }}>
                    <PlusIcon width="24" height="24" />
                  {xl8('createGroup')}
                </li> 
              </ul>
            </div>
          </div>
          <div className="col-lg-9 col-md-8">
            <div className="card">
                <div className="card-body no-top-padding">
                  <h2 className="page-title card-body-page-title">
                    {xl8('accessCards')}
                  </h2>
                  
                  {/* <div>
                    {this.state.accessCards &&
                      this.state.accessCards.length || 0} cards
                  </div> */}

                  <PaginationControl
                    count={this.accessCardCount()}
                    displayedPages={5}
                    page={this.state.accessCardPage}
                    visible={10}
                    notNeeded={'hidden'}
                    onPageChange={(page, count) => {
                      this.setState({
                        accessCardPage: page,
                        accessCardPageOffset: page * count,
                        accessCardPageCount: count
                      });
                    }}/>
                  <div className="scrollable">
                    <div>
                      <table className="table table-sorter card-table" 
                        cell-padding={1} cell-spacing={1}>
                        <thead className="text-primary">
                          <tr>
                            <th scope="col"
                                onClick={(event) => {
                                  this.setSortOrder('group');
                                }}>
                              {xl8('group')}
                              {this.sortOrderIndication('group')}
                            </th>
                            <th scope="col"
                                onClick={(event) => {
                                  this.setSortOrder('name');
                                }}>
                              {xl8('idName')}
                              {this.sortOrderIndication('name')}
                            </th>
                            <th scope="col"
                                onClick={(event) => {
                                  this.setSortOrder('date');
                                }}>
                              {xl8('dateAdded')}
                              {this.sortOrderIndication('date')}
                            </th>
                            <th scope="col"
                                onClick={(event) => {
                                 // this.setSortOrder('status');
                                }}>
                              Status
                              {/* {this.sortOrderIndication('status')} */}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.renderAccessCards()}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
        {/* <div className="row access-control-plans">
        <div className="col-lg-12 col-md-12">
          <div className="card">
              <div className="card-body">
                <h2>Access Control Plans</h2>
                <button onClick={(event) => this.onEditAccessControlPlan(null)}
                  className="btn btn-primary control-plan">
                  <i className="material-icons">
                    post_add
                  </i> 
                  Create Plan
                </button>
                <table className="table table-sorter">
                  <thead className="text-primary">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Access Method</th>
                      <th scope="col">Groups</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.sortedAccessControlPlans().map((plan) => {
                      return (
                        <tr key={plan.id}>
                          <td data-label="Name">{plan.name}</td>
                          <td data-label="Access Method">RFID Card</td>
                          <td data-label="Groups" className="access-group-column">
                            <ul>{
                              this.planGroupsByPlanId(plan.id).map((group) => {
                                return <li key={group.id}>
                                  {group.name}
                                </li>;
                              })
                            }</ul>
                          </td>
                          <td>
                            <Dropdown
                              icon='ellipsis horizontal'>
                              <Dropdown.Menu className="left pointing">
                                <Dropdown.Item icon='edit' text={xl8('editPlan')}
                                  onClick={(event) => {
                                    this.onEditAccessControlPlan(plan);
                                  }}
                                  />
                                <Dropdown.Item icon='delete' text={xl8('deletePlan')}
                                  onClick={(event) => {
                                    this.onDeleteAccessControlPlan(plan);
                                  }} />
                              </Dropdown.Menu>
                            </Dropdown>
                            </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div> */}
        {/* <WizardAuthorizeAccessCard 
          ref={(wizard) => this.authorizeAccessCardWizard = wizard }
          /> */}
      </>
    );
  }

  accessCardCount(): number {
    return this.getAccessCards().length;
  }

  renderAccessCards(): ReactNode {
    if (!this.state.accessCards || !this.state.accessControlPlans)
      return <SwychedSpinner busy={1}/>;
    
    let cards = this.getVisibleAccessCards();

    if (!cards || !cards.length) {
      let becauseSearch = 
        (this.state.accessCards?.length || 0) > 0
        ? 'match the search criteria'
        : null;
      return (
        <tr>
          <td colSpan={4}>
            <div>
              <div className="empty-container-overlay text-center">
                <NoAccessCardsIcon width="96px" height="96px" />
                  <h3 className="empty-state-header">
                    {xl8('noAccessCardsHeader')} 
                    {/* Fix formatting before re-enabling {becauseSearch} */} 
                  </h3>
                  <div className="empty-state-subheader">
                    {xl8('noAccessCardsText')}
                  </div>
              </div>
            </div>
          </td>
        </tr>
      );
    }

    return cards.map((card) => {
      return (
        <tr key={card.id}
            tabIndex={0}>
          <td data-label="Group">{
            this.getGroupById(card.acpgId)?.name
          }</td>
          <td data-label="ID / Name">
            {card.name}
          </td>
          <td data-label="Date Scanned">
            {formatUnixDate(card.dateAdded)}
          </td>
          <td data-label="Card Status">
            <span className="status-bubble status-authorized">
              <span className="status-square-indicator"></span>
              Authorized
            </span>
          </td>
          <td>
            <Dropdown
              icon={<EllipsisIcon width="28" height="28" fill="#C1C5C8"/>}>
              <Dropdown.Menu className="left pointing">
                <Dropdown.Item 
                  icon={<CogIcon width="24" height="24" fill="rgb(193, 197, 200)"/>}
                  text={xl8('editAccessCard')}
                  onClick={(event) => {
                    this.onEditRFID(card);
                  }}
                  />
                <Dropdown.Item 
                  icon={<DisableCardIcon width="24" height="24" fill="rgb(193, 197, 200)"/>}
                  text={'Disable access card'}
                  onClick={(event) => {
                    this.onDisableAccessCard(card);
                  }} 
                />
                {/* <Dropdown.Item 
                  icon={<AuthorizeCardIcon width="24" height="24" fill="rgb(193, 197, 200)"/>}
                  text={'Authorize access card'}
                  onClick={(event) => {
                    this.onAuthorizeAccessCard(card);
                  }} 
                /> */}
                <Dropdown.Item 
                  icon={<DeleteIcon width="24" height="24" fill="rgb(193, 197, 200)"/>}
                  text={xl8('deleteAccessCard')}
                  onClick={(event) => {
                    this.onDeleteAccessCard(card);
                  }} />
                  
              </Dropdown.Menu>
            </Dropdown>
          </td>
        </tr>
      );
    });
  }

  renderGroupList(groupList: AccessControlGroup[]): ReactNode {
    // Avoid flash of unwanted content, show absolutely nothing at startup
    if (!this.state.accessCards || !this.state.accessControlPlans)
      return <></>;
    
    if (this.groupElements.length > groupList.length)
      this.groupElements.splice(groupList.length, 
        this.groupElements.length - groupList.length);
    
    while (this.groupElements.length < groupList.length)
      this.groupElements.push(React.createRef<HTMLLIElement>());

    if (!groupList.length) {
      return (
        <div className="text-center empty-group-list">
          {xl8('groupListEmpty')}
        </div>
      );
    }
    
    return groupList.map((group, index) => {
      return (
        <li key={group.id}
          ref={this.groupElements[index]} 
          tabIndex={0}
          className={
            (this.state.groupFilter && 
              +this.state.groupFilter.id === +group.id
            ? 'selected' : '') + ' ' +
            (this.isGroupEmpty(group)
            ? 'selectable-empty' : '')
          }
          onKeyDown={(event) => {
            this.onGroupKeyDownHandler(event, groupList, group, index);
          }}
          onClick={(event) => {
            this.setState({
              groupFilter: group,
              accessCardPage: 0,
              accessCardPageOffset: 0
            });
          }}>
          <span className="sidebar-list-item text-truncate">
            {group.name}
          </span>
          <Dropdown
            icon={<EllipsisIcon width="28" height="28" fill="#C1C5C8"/>}
            className="left-sidebar-control-icon">
            <Dropdown.Menu className="left pointing">
              <Dropdown.Item 
                icon={<CogIcon width="20" height="20" fill="rgb(193, 197, 200)"/>}
                text={xl8('editGroup')}
                onClick={(event) => {
                  this.editAccessControlGroup(group);
                }}
                />
              <Dropdown.Item 
                icon={<DeleteIcon width="20" height="20" fill="rgb(193, 197, 200)"/>}
                text={xl8('deleteGroup')}
                onClick={(event) => {
                  this.onDeleteAccessControlGroup(group);
                }} />
            </Dropdown.Menu>
          </Dropdown>
        </li>
      );
    });
  }

  private onGroupKeyDownHandler(event: React.KeyboardEvent<HTMLLIElement>,
      groupList: AccessControlGroup[], 
      group: AccessControlGroup | null, index: number) {
    if (event.ctrlKey)
      return;
    console.log(event.key);
    switch (event.key) {
      case 'Escape':
        (event.target as HTMLElement)?.blur();
        break;
      
      case 'Enter':
      case 'NumpadEnter':
        // Hitting enter triggers a click event
        event.preventDefault();
        (event.target as HTMLElement)?.click();
        break;
      
      case 'ArrowUp':
        if (index < 0)
          break;
        
        event.preventDefault();
        this.setState({
          groupFilter: index > 0 ? groupList[index - 1] : null,
          accessCardPage: 0,
          accessCardPageOffset: 0
        });

        if (index - 1 < 0)
          this.allGroupsElement.current?.focus();
        else
          this.groupElements[index - 1].current?.focus();
        
        break;
      
      case 'ArrowDown':
        if (index + 1 < groupList.length) {
          event.preventDefault();
          this.setState({
            groupFilter: groupList[index + 1],
            accessCardPage: 0,
            accessCardPageOffset: 0
          });
          this.groupElements[index + 1].current?.focus();
        }
        break;

      }
  
  }
  
  isGroupEmpty(group: AccessControlGroup): boolean {
    let cards = this.state.accessCards;
    // When there are no cards, every group is empty
    if (!cards || !cards.length)
      return true;
    return !cards.some((card) => {
      return +card.acpgId === +group.id;
    });
  }

  setSortOrder(field: 'group' | 'name' | 'date'): void {
    if (field === this.state.sortBy) {
      this.setState({
        sortDir: -this.state.sortDir
      });
      return;
    }

    this.setState({
      sortBy: field,
      sortDir: 1
    });
  }

  getVisibleAccessCards(): AccessCard[] {
    return this.getAccessCards().slice(this.state.accessCardPageOffset, 
      this.state.accessCardPageOffset + this.state.accessCardPageCount);
  }

  getAccessCards(): AccessCard[] {
    if (this.state.accessCards) {      
      let sortCallback: (a: AccessCard, b: AccessCard) => number = null;
      switch (this.state.sortBy) {
        case 'group':
          sortCallback = (a: AccessCard, b: AccessCard) => {
            let groups = this.state.accessControlPlans &&
              this.state.accessControlPlans.groups;
            return this.state.sortDir * (groups
              ? groups[a.acpgId].name.toLocaleUpperCase().localeCompare(
                  groups[b.acpgId].name.toLocaleUpperCase())
              : 0);
          };
          break;

        case 'name':
          sortCallback = (a: AccessCard, b: AccessCard) => {
            return this.state.sortDir * 
                a.name.toLocaleUpperCase().localeCompare(
                b.name.toLocaleUpperCase());
          };
          break;
        
        case 'date':
          sortCallback = (a: AccessCard, b: AccessCard) => {
            return a.dateAdded < b.dateAdded ? -this.state.sortDir :
              b.dateAdded < a.dateAdded ? this.state.sortDir : 
              0;
          };
          break;

        default:
          console.log('invalid sortBy:', this.state.sortBy);
          sortCallback = (a: AccessCard, b: AccessCard) => {
            return 0;
          };
      }

      return this.state.accessCards.filter((card) => {
        let upperTerm = this.state.searchTerm.toLocaleUpperCase();
        return (!this.state.searchTerm || 
          card.name.toLocaleUpperCase().indexOf(upperTerm) >= 0) &&
          (!this.state.groupFilter ||
            +card.acpgId === +this.state.groupFilter.id);
      }).sort(sortCallback);
    }
    return [];
  }

  sortOrderIndication(field: string): ReactNode {
    if (this.state.sortBy !== field)
      return '';
    
    return this.state.sortDir > 0
      ? <span className="material-icons-outlined sort-icon">arrow_drop_up</span>
      : <span className="material-icons-outlined sort-icon">arrow_drop_down</span>;
  }

  // refreshAccessCardList(organizationId: number): Promise<AccessCard[]>{
  //   let cardPromise = getAccessCardsByOrganization(organizationId);
  
  //   return cardPromise.then((cards) => {      
  //     if (this.state.searchTerm) {
  //       cards = cards.filter((card) => {
  //         return card.name.indexOf(this.state.searchTerm) >= 0;
  //       });
  //     }

  //     //console.log('Complete access card list:', cards)
  //     this.setState({
  //       accessCards: cards
  //     });
  //     return cards;
  //   });
  // }  

  getGroupById(groupId: number): AccessControlGroup {
    if (this.state.accessControlPlans && 
        this.state.accessControlPlans.groups[groupId])
      return this.state.accessControlPlans.groups[groupId];
    
    return {
      id: null,
      name: '',
      organizationId: 0
    };
  }

  groupList(): AccessControlGroup[] {
    return (this.state.accessControlPlans &&
      this.state.accessControlPlans.groups &&
      Object.values(this.state.accessControlPlans.groups)) ||
      [];
  }

  planGroupsByPlanId(planId: number): AccessControlGroup[] {
    if (!this.state.accessControlPlans)
      return [];
    
    console.log('planId', planId);

    let planGroups = this.state.accessControlPlans.planGroups;
    let groups = this.state.accessControlPlans.groups;
    let memberships = planGroups[planId] || [];

    return memberships.map((planGroup) => {
      return groups[planGroup.acpgId];
    });
  }

  planList(): AccessControlPlan[] {
    return (this.state.accessControlPlans &&
      this.state.accessControlPlans.plans &&
      Object.values(this.state.accessControlPlans.plans)) ||
      [];
  }

  sortedAccessControlPlans(): AccessControlPlan[] {
    return this.planList().sort((a, b) => {
      return a.name < b.name ? -1 :
        b.name < a.name ? 1 : 
        0;
    });
  }

  authorizeRFID(): Promise<void> {
    //console.assert(this.authorizeAccessCardWizard);

    return ModalFactory.withDialog(WizardAuthorizeAccessCard, 
    (authorizeAccessCardWizard) => {
      return getAllSiteDevices(this.state.organization.id)
      .then((siteDevices) => {
        let initialResult: WizardAuthorizeAccessCardResult = {
          organizationId: this.state.organization.id,
          accessControlPlans: this.state.accessControlPlans,
          siteDevices: siteDevices,
          totalAdded: 0
        };
        return authorizeAccessCardWizard.showDialog(initialResult)
        .then((result) => {
          if (initialResult.totalAdded > 0)
            toastSuccess('Added ' + initialResult.totalAdded + ' cards');
          //siteDevices: siteDevices
        }).then((result) => {
          this.refreshAccessControlPlans(this.state.organization);
        });
      });
    }).catch((err) => {
      toastError(err.message);
    });
  }

  onEditAccessControlPlan(plan: AccessControlPlan): void {
    let plans = this.state.accessControlPlans;
    if (!plans)
      return;
    
    ModalFactory.withDialog(ModalEditAccessControlPlan, 
    (editAccessControlPlanModal) => {
      return editAccessControlPlanModal.showDialog(
        this.state.organization.id, plans, plan)
      .then((result) => {
        if (result)
          this.refreshAccessControlPlans(this.state.organization);
      });
    });
  }

  onEditRFID(card: AccessCard): Promise<void> {
    return ModalFactory.withDialog(ModalEditAccessCard, 
    (editAccessCardModal) => {
      return editAccessCardModal.showDialog(card, 
          this.state.organization.id, this.state.accessControlPlans.groups)
      .then((result) => {
        if (!result)
          return;
  
        this.refreshAccessControlPlans(this.state.organization);
        toastSuccess('Access card "' + result.card.name + 
          '" updated successfully');
      });
    });
  }

  editAccessControlGroup(group: AccessControlGroup): void {
    ModalFactory.withDialog(ModalEditAccessControlGroup, 
    (editAccessControlGroupModal) => {
      return editAccessControlGroupModal.showDialog(
        this.state.organization.id, group)
      .then((result) => {
        if (!result)
          return null;
        
        // Keep state immutable

        let accessControlPlansClone = {
          ...this.state.accessControlPlans
        };
        accessControlPlansClone.groups = {
          ...accessControlPlansClone.groups,          
        };

        accessControlPlansClone.groups[result.group.id] = result.group;

        return setStatePromise<AccessControlSheetState, 
            AccessControlSheet>(this, {
          accessControlPlans: accessControlPlansClone
        }).then(() => result);
      }).then((result) => {
        if (!result)
          return null;
        
        toastSuccess('Group "' + result.group.name + 
          '" updated successfully');
  
        return this.refreshAccessControlPlans(this.state.organization);
      });
    });
  }

  onDeleteAccessControlGroup(accessControlGroup: AccessControlGroup): void {
    ModalFactory.withDialog(ModalConfirm, (confirmModal) => {
      return confirmModal.showDialog(
        'Are you sure you want to delete "' + accessControlGroup.name + '"?')
      .then((result) => {
        if (!result || !result.ok)
          return;
  
        deleteAccessControlGroupById(this.state.organization.id,
            accessControlGroup.id).then(() => {
          toastSuccess('Group "' + accessControlGroup.name + 
            '" deleted successfully');
          this.refreshAccessControlPlans(this.state.organization);
        }).catch((err) => {
          toastError('Error deleting access control group: ' + err.message);
        });
      });
    });
  }

  onDeleteAccessControlPlan(accessControlPlan: AccessControlPlan): void {
    ModalFactory.withDialog(ModalConfirm, (confirmModal) => {
      return confirmModal.showDialog(
        'Are you sure you want to delete ' + accessControlPlan.name + '?')
      .then((result) => {
        if (!result || !result.ok)
          return;
        
        deleteAccessControlPlanById(
          this.state.organization.id, accessControlPlan.id).then(() => {
            toastSuccess('Plan "' + accessControlPlan.name + 
            '" deleted successfully');
          // update the access control plan list
        }).then(() => {
          toastSuccess('Deleted access control plan "' + 
            this.planGroupsByPlanId.name + '"');
          
          // Full refresh to pick up possible complex update involving
          // automatic deletion of group memberships through foreign key
          this.refreshAccessControlPlans(this.state.organization);
        }).catch((err) => {
          toastError('Error deleting access control plan: ' + err.message);
        });
      });
    });
  }

  onDeleteAccessCard(RFID: AccessCard): void {
    ModalFactory.withDialog(ModalConfirm, (confirmModal) => {
      return confirmModal.showDialog(
        'Are you sure you want to delete "' + RFID.name + '"?')
      .then((result) => {
        if (!result || !result.ok)
          return null;
  
        return deleteAccessCardById(this.state.organization.id,
            RFID.id).then(() => {
          toastSuccess('Access card "' + RFID.name + 
            '" deleted successfully');
          
          this.refreshAccessControlPlans(this.state.organization);
        }).catch((err) => {
          toastError('Error deleting access card: ' + err.message);
        });
      });
    });
  }

  onDisableAccessCard(RFID: AccessCard): void {
    ModalFactory.withDialog(ModalConfirm, (confirmModal) => {
      return confirmModal.showDialog(
        'Are you sure you want to disable "' + RFID.name + '"?')
      .then((result) => {
        if (!result || !result.ok)
          return null;
  
          // PATCH access card
          
          this.refreshAccessControlPlans(this.state.organization);

      });
    });
  }

  onAuthorizeAccessCard(RFID: AccessCard): void {
    ModalFactory.withDialog(ModalConfirm, (confirmModal) => {
      return confirmModal.showDialog(
        'Are you sure you want to authorize "' + RFID.name + '"?')
      .then((result) => {
        if (!result || !result.ok)
          return null;
  
        // PATCH access card
          
          this.refreshAccessControlPlans(this.state.organization);

      });
    });
  }
}
