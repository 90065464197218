import React from "react";
import { 
  AccessCard,
  AccessControlPlansResponse, 
  Device, 
  listenForCardAtEvse, 
  patchPostAccessCardByOrganization, 
  Site, 
  SiteDevices
} from "../../api";
import { xl8 } from "../../translations/i18n";
import { AccessControlGroupDropdown } from "../AccessControlGroupDropdown";
import { AccessControlIcon } from "../icons/AccessControlIcon";
import { LeftArrowIcon } from "../icons/LeftArrowIcon";
import { NoAccessCardsIcon } from "../icons/NoAccessCardsIcon";
import { RightArrowIcon } from "../icons/RightArrowIcon";
import { TapRFIDIcon } from "../icons/TapRFIDIcon";
import { 
  formatUnixDate, renderSiteDevicesOptions, 
  toastError, toastSuccess
} from "../shared/ui";
import { Wizard } from "./Wizard";

export interface WizardAuthorizeAccessCardResult {
  organizationId: number;
  accessControlPlans: AccessControlPlansResponse;
  siteDevices: SiteDevices;
  totalAdded: number;
}

export class WizardAuthorizeAccessCard 
    extends Wizard<WizardAuthorizeAccessCardResult> {
  private site: Site = null;
  private evse: Device = null;
  private addedCards: AccessCard[] = [];
  private listeningForRfid = false;
  private listenSince: number = -1;

  private accessControlPlans: AccessControlPlansResponse;
  private pollAbortCtrl = new AbortController();
  private organizationId: number;
  private siteDevices: SiteDevices;

  private pollTimeout: NodeJS.Timer = null;

  static readonly pollDelayMs: number = 2000;

  prepareWizard(): Promise<WizardAuthorizeAccessCardResult> {
    this.organizationId = this.result.organizationId;
    this.accessControlPlans = this.result.accessControlPlans;
    this.siteDevices = this.result.siteDevices;
    return this.setPageByIndex(0).then(() => {
      return this.show();
    });
  }

  constructor(props: {}) {
    super(props);

    this.addPage({
      name: 'deviceSelect', 
      title: xl8("authorizeAccessCard") + ' - ' + xl8('deviceSelection'),
      nextHook: () => {
        if (!this.evse)
          toastError('Select a device');
        return !!this.evse;
      },
      nextContent: <>
       <div className="next-btn">
         {xl8('next')} {<RightArrowIcon width="20" height="20" fill="#fff"/>}
        </div>
      </>,
      content: () => <>
            {/* {value={this.wtf('deviceId', this.deviceId)}} */}
        <p>
          {xl8('selectTheDeviceDesc')}
        </p>
        <br/>
        <label>
          {xl8('device')}
          <select className="form-select"
              defaultValue={(this.evse && this.evse.id) || ''}
              onChange={(event) => {
                console.log('changed device to ', event.target.value);
                this.onDeviceSelected(+event.target.value);
              }}>
            <option value="">
              {xl8('selectADevice')}
            </option>

            {renderSiteDevicesOptions(this.siteDevices)}
          </select>
        </label>
      </>
    });

    this.addPage({
      name: 'tapRFID', 
      title: xl8("authorizeAccessCard") + ' - ' + xl8("cardRead"),
      backContent: <>
        <LeftArrowIcon width="24" height="24" fill="#C1C5C8" /> {xl8('back')}
      </>,
      nextContent: '',
      nextHook: () => {
        return true;
      },
      onActivate: () => {
        this.startListeningForRfid();
      },
      onDeactivate: () => {
        this.stopListeningForRfid();
      },
      content: () => <>
        <p className="tap-card-desc">
          {xl8('tapAccessCardDesc')} “{this.evse.name}” at {this.site.name}.
        </p>
        <div className="tap-rfid">
          <NoAccessCardsIcon width="220px" height=""/>
        </div>
        {/* <p className="tap-card-continue">
          Click <b>Continue</b> when you are done.
        </p> */}
      </>
    });

    this.addPage({
      name: 'assignGroup', 
      title: xl8("authorizeAccessCard") + ' - Group',
      backContent: <>
        <LeftArrowIcon width="20" height="20" fill="#C1C5C8" /> {xl8("back")}
      </>,
      nextContent: <>
        <AccessControlIcon width="24" height="24" fill="#fff" /> {xl8("authorize")}
      </>,
      nextHook: () => {
        if (this.addedCards.length === 0)
          return true;
        return this.saveCards();
      },
      extraButtons: [{
        content: <>
          {xl8("authorizeMore")}
        </>,
        onClick: (event) => {
          this.saveCards().then((worked) => {
            if (worked) {
              ++this.result.totalAdded;
              this.setPageByName('tapRFID');
            }
          });
        }
      }],
      content: () => <>
        <label>
          {xl8("dateAdded")}
          {/* (??? {this.scanDate}) */}
          <input type="text" className="form-control" 
            defaultValue={formatUnixDate(this.lastEntry().dateAdded)}
            readOnly={true}/>
        </label>
        <label>
          RFID Value
          <input type="text" className="form-control" 
            defaultValue={this.lastEntry().cardValue}
            readOnly={true}
            onKeyDown={(event) => {
              toastError('Click Back to scan a card with a different value');
            }}/>
        </label>
        <label>
          Name / ID
          <input type="text" className="form-control"
            defaultValue={this.lastEntry().name}
            onChange={(event) => {
              this.lastEntry().name = event.target.value;
            }}/>
          <span className="input-desc">
            Enter a descriptive name or ID to identify the card assignee
          </span>
        </label>
        <label>
          {xl8("group")}
          <AccessControlGroupDropdown
            organizationId={this.organizationId}
            groups={this.accessControlPlans && this.accessControlPlans.groups}
            value={this.lastEntry().acpgId}
            onChange={(acpgId) => {
                this.lastEntry().acpgId = acpgId;
            }}/>
        </label>
      </>
    });
  }

  private saveCards(): Promise<boolean> {
    let card = this.addedCards[0];
    if (card.acpgId === 0) {
      toastError('Group not selected');
      return Promise.resolve(false);
    }
    if (!/\S+/.test(card.name)) {
      toastError('Card Name/ID cannot be empty');
      return Promise.resolve(false);
    }

    return patchPostAccessCardByOrganization(
      'POST', this.organizationId, card)
    .then((cards) => {
      toastSuccess(this.addedCards.length + ' card' + 
        (this.addedCards.length !== 1 ? 's' : '') + ' added');
      //this.addedCards = cards;
      this.addedCards.splice(0, this.addedCards.length);
      return true;
    }).catch((err) => {
      toastError(err.message);
      return false;
    });
  }

  lastEntry(): AccessCard {
    return this.addedCards[this.addedCards.length - 1];
  }

  // Look through the devices in each site
  // and capture the location and device id of the selected device
  onDeviceSelected(deviceId: number): void {
    let siteIds = Object.keys(this.siteDevices.deviceListBySite);
    let result: Device | null = null;
    this.evse = null;
    siteIds.some((siteId) => {
      let deviceList: Device[] = this.siteDevices.deviceListBySite[siteId];
      deviceList.find((device) => {
        let isMatch = +device.id === +deviceId;
        if (isMatch) {
          this.site = this.siteDevices.sites.find((site) => {
            return +site.id === +siteId;
          });
          this.evse = device;
        }
        return isMatch;
      });
      return result !== null;
    });
  }
  
  private getSites(): Site[] {
    return this.siteDevices ? this.siteDevices.sites : [];
  }
  
  private startListeningForRfid(): void {
    this.pollAbortCtrl = new AbortController();
    this.listeningForRfid = true;
    this.pollRfid();
  }


  private pollRfid(): void {
    listenForCardAtEvse(this.organizationId, 
        +this.site.id, +this.evse.id, this.listenSince, 
        this.pollAbortCtrl).then((result) => {
      if (!this.listeningForRfid)
        return;
        
      this.listenSince = result.lastId;

      let cards = result.cards || [];

      if (cards.length === 0) {
        this.pollTimeout = setTimeout(() => {
          this.pollTimeout = null;
          if (this.listeningForRfid)
            this.pollRfid();
        }, WizardAuthorizeAccessCard.pollDelayMs);
        return;
      }

      // Throw away all but latest tap because flow is forced one at a time
      let latestTap = cards[0];

      this.addedCards.splice(0, this.addedCards.length);
      this.addedCards.push({
        id: null,
        acpgId: 0,
        name: '',
        cardValue: latestTap.cardValue,
        dateAdded: latestTap.dateAdded
      });

      this.setPageByName('assignGroup');
    }).catch((err) => {
      if (this.listeningForRfid) {

        // Randomize retry delay over 1.5 to 3.0 second range
        setTimeout(() => {
          this.pollRfid();
        }, Math.random() * 1500 + 1500);
      }
    });
  }

  private stopListeningForRfid(): void {
    if (this.pollTimeout) {
      clearTimeout(this.pollTimeout);
      this.pollTimeout = null;
    }

    this.listeningForRfid = false;
    this.pollAbortCtrl.abort();
  }

}
