import * as React from 'react';
import { Organization } from '../api';
import { OrganizationChangeListener } from '../App';
import { Dropdown } from 'semantic-ui-react';
import { PureComponent, ReactNode } from 'react';

import './SheetIntegration.scss';

import { ModalPaymentIntegration } from './modals/ModalPaymentIntegration';
import { ModalFactory } from './modals/Modal';
import { ModalIntegrationUsage } from './modals/ModalIntegrationUsage';
import { ScheduleEntry } from './schedule-view.component';
import { xl8 } from '../translations/i18n';
import { EllipsisIcon } from './icons/EllipsisIcon';
import { isDemo, toastSuccess } from './shared/ui';
import { PlusIcon } from './icons/PlusIcon';
import { CopyIcon } from './icons/CopyIcon';
import { DeleteIcon } from './icons/DeleteIcon';

export interface IntegrationSheetProps {
  organization: Organization;
  visible: boolean;
}

interface IntegrationSheetState {
  testScheduleEntries: ScheduleEntry[];
}

export class IntegrationSheet 
    extends PureComponent<IntegrationSheetProps, IntegrationSheetState>
    implements OrganizationChangeListener {
  constructor(props: IntegrationSheetProps) {
    super(props);

    this.state = {
      testScheduleEntries: [{
        startDay: 1,
        endDay: 3,
        startMinute: 30,
        endMinute: 240
      }]
    };
  }
  
  organizationChanged(org: Organization): Promise<void> {
    // do something someday
    return Promise.resolve();
  }

  private paymentPlanList = [
    {
      "id" : "1",
      "name": "Peak Power Plan"
    } 
    , {
      "id" : "2",
      "name": "Regular Rate"
    } 
    , {
      "id" : "3",
      "name": "Special Rate"
    }
  ]

  private paymentScheduleList = [
    {
      "id" : "1",
      "name": "CoV Schedule"
    } 
    , {
      "id" : "2",
      "name": "UBC Schedule"
    } 
    , {
      "id" : "3",
      "name": "Vancity Schedule"
    }
  ]


  public paymentIntegration = [
    {
      "id": "1",
      "name": "UIC Payworld",
      "icon": "uic.svg",
      "description": "Allow users to pay for charging sessions using" +
        " the UIC point-of-sales terminal",
      "apiKey": "d358c018ac8b03e89afa8d",
      "enabled": true
    },
    {
      "id": "2",
      "name": "Honk Mobile",
      "icon": "honk.svg",
      "description": "Allow users to pay for charging sessions when" +
        " they pay for parking using the Honk mobile app.",
      "apiKey": "d358c018ac8b03e89afa8d",
      "enabled": true
    },
    {
      "id": "3",
      "name": "PayByPhone",
      "icon": "paybyphone.svg",
      "description": "Allow users to pay for charging sessions with PayByPhone.",
      "apiKey": "d358c018ac8b03e89afa8d",
      "enabled": true
    },
    {
      "id": "4",
      "name": "PayPal",
      "icon": "paypal.svg",
      "description": "Allow users to pay for charging sessions using PayPal.",
      "apiKey": "d358c018ac8b03e89afa8d",
      "enabled": false
    }
  ]


  // private tabs: HorizontalTab[] = [{
  //   title: 'Pricing Plans',
  //   icon: '',
  //   className: 'pricing-plans-tab',
  //   body: (tab) => 
  //     <React.Fragment key={'pricingPlans'}>
  //       <h2 className="sheet-title">Pricing Plans</h2>
  //         <div className="row access-control">
  //           <div className="col-lg-2 col-md-4">
  //             <button className="btn btn-primary sidebar-primary-btn"
  //                 type="button" 
  //                 // Create a payment plan onClick
  //                 >
  //               <i className="material-icons">
  //                 add_circle_outline
  //               </i> 
  //               Create Payment Plan
  //             </button>

  //             <div className="input-group search-filter">
  //               <input type="search" className="form-control"
  //                   placeholder="Search Payment Plans" aria-label="Search"
  //                   // value={this.state.searchTerm}
  //                   // onChange={(event) => {
  //                   //   this.onSearchTermChange(event.target.value);
  //                   // }}
  //                   aria-describedby="search-addon" />
  //               <button className="btn btn-outline"
  //                   type="button" 
  //                   // tabIndex={!this.state.searchTerm ? -1 : 0}
  //                   onClick={(event) => {
  //                     this.setState({
  //                       searchTerm: '',
  //                       accessCardPage: 0 
  //                     });
  //                   }}>
  //                 {
  //                     // !this.state.searchTerm 
  //                     // ? <i className="material-icons">search</i>
  //                     // : <i className="material-icons">close</i>
  //                 }
  //               </button>
  //             </div>

  //             <div className="left-side-bar-filter">
  //               <ul>
  //                 <li
  //                     onClick={(event) => {
  //                       this.setState({
  //                         groupFilter: null,
  //                         accessCardPage: 0
  //                       });
  //                     }}
  //                     tabIndex={0}>
  //                   All Plans
  //                 </li>
  //                 <hr className="list-separator"/>
  //                 {this.renderPaymentPlanList(this.paymentPlanList)}
  //                 <hr className="list-separator"/>
  //               </ul>
  //             </div>
  //           </div>
  //           <div className="col-lg-10 col-md-8">
  //             <div className="card">
  //               <div className="card-body no-top-padding">
  //                 <h2 className="page-title card-body-page-title">
  //                   Pricing Plans
  //                 </h2>
  //                 <table className="table table-sorter card-table">
  //                   <thead className="text-primary">
  //                     <tr>
  //                       <th scope="col"
  //                           onClick={(event) => {
  //                           }}>
  //                         Device Type
  //                       </th>
  //                       <th scope="col"
  //                           onClick={(event) => {
  //                           }}>
  //                         API Integration
  //                       </th>
  //                       <th scope="col"
  //                           onClick={(event) => {
  //                           }}>
  //                         Swyched App
  //                       </th>
  //                       <th scope="col"
  //                           onClick={(event) => {
  //                           }}>
  //                         POS Device
  //                       </th>
  //                     </tr>
  //                   </thead>
  //                   <tbody>
  //                     {/* Render payment plan list */}
  //                   </tbody>
  //                 </table>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //     </React.Fragment>
  //   }, {
  //     title: 'Pricing Schedules',
  //     icon: '',
  //     body: (tab) =>
  //       <React.Fragment key={'pricingSchedules'}>
  //         <h2 className="sheet-title">Pricing Schedules</h2>
  //         <div className="row access-control">
  //           <div className="col-lg-2 col-md-4">
  //             <button className="btn btn-primary sidebar-primary-btn"
  //                 type="button" 
  //                 // Create a payment schedule 
  //                 >
  //               Create Payment Schedule
  //             </button>

  //             <div className="input-group search-filter">
  //               <input type="search" className="form-control"
  //                   placeholder="Search Schedules" aria-label="Search"
  //                   aria-describedby="search-addon" />
  //               <button className="btn btn-outline"
  //                   type="button"> 
  //               </button>
  //             </div>

  //             <div className="left-side-bar-filter">
  //               <ul>
  //                 <li
  //                     tabIndex={0}>
  //                   All Schedules
  //                 </li>
  //                 <hr className="list-separator"/>
  //                 {/*Render Schedule List*/}
  //               </ul>
  //             </div>
  //           </div>
  //           <div className="col-lg-10 col-md-8">
  //             <div className="card">
  //               <div className="card-body no-top-padding">
  //                 <h2 className="page-title card-body-page-title">
  //                   Payment Schedules
  //                 </h2>
  //                 <PaymentSchedule></PaymentSchedule>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </React.Fragment>
  //   }
  // ];


  // private renderPaymentTabs(): ReactNode {
  //   return (
  //     <>
  //       <h2 className="page-title card-body-page-title">
          
  //       </h2>
  //       <HorizontalTabContainer
  //         tabs={this.tabs}
  //         invisibleTabsDisappear={false}
  //         lazy={true}
  //         unloadTimeoutMs={10000}
  //         />
  //     </>
  //   );
  // }

  render(): JSX.Element {
    if (!this.props.visible)
      return <div>Hidden tab panel</div>;

    const iconImgPath = 'images/';
    return (
      <>

        {/* <div className="page-title-container">
          <h2 className="sheet-title">API Keys</h2>
          <div className="sheet-subtitle">
          These secret keys grant external apps access to our API.  
            Copy the API key and send it to the developers of the app 
            you would like to integrate. API documentation can be accessed 
            at <a href="https://swyched.com/docs/api" target="_blank">
              swyched.com/docs/api</a>
          </div>
        </div> */}
        <div className="integration-container">
          <div className="row sheet-header">
            <div className="page-title-container col-lg-9">
              <h2 className="sheet-title">
                {xl8('integrations')}
                <span className="beta-tag"
                  hidden={isDemo()}>
                  {xl8('comingSoon')}
                </span>
              </h2>
              <div className="page-description">
              {xl8('APIDesc')} 
              <a href="https://swyched.com/docs/api" 
                target="_blank" rel="noreferrer"> swyched.com/docs/api</a>
              </div>
            </div>
            <div className="sheet-control-container col-lg-3">
              <button className="btn btn-primary
                pull-right"
                onClick={(_) => this.addPaymentIntegration()}
                >
                <PlusIcon width="21" height="21" fill="#fff"/>
                {xl8('generateAPIKey')}
              </button>
            </div>
          </div>

          <div className="row integration-cards">
            { 
              this.paymentIntegration.map((integration) => {
                return (
                  <div className="col-lg-4 col-md-6 col-sm-12"
                      key={integration.id}>
                    <div className="card">
                      <div className="card-body">
                        <img src={iconImgPath + integration.icon}
                            className="integration-icon" alt=""/>
                        <h2 className="integration-header">
                          {integration.name}
                        </h2>
                        <div className="form-check form-switch 
                          integration-switch">
                          <input className="form-check-input"
                            checked={integration.enabled}
                            type="checkbox" />
                        </div>
                        <div className="integration-desc">
                          {integration.description}
                        </div>
                        {/* <div className="integration-token-container">
                          <label>API Key</label>
                          <span>{integration.apiKey}</span>
                          <button className="copy-api-key"
                            onClick={(event) => {
                              this.copyAPIKey('d358c018ac8b03e89afa8d');
                            }}>
                            <span>
                              <CopyIcon width="18" height="18" fill="#C1C5C8"/>
                            </span>
                          </button>
                        </div> */}
                        {/* <div className="integration-settings">
                          <Dropdown
                            icon={<EllipsisIcon width="28" height="28" fill="#C1C5C8"/>}>
                            <Dropdown.Menu className="left pointing">
                              <Dropdown.Item icon='info' text={xl8('viewUsage')}
                                onClick={(event) => {
                                  this.viewIntegrationUsage();
                                }}
                                />
                              <Dropdown.Item 
                                icon={<DeleteIcon width="20" height="20" fill="rgb(193, 197, 200)"/>}
                                text={xl8('remove')}
                                onClick={(event) => {

                                }} />
                            </Dropdown.Menu>
                          </Dropdown>
                        </div> */}
                      </div>
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
        {/* <ScheduleView
          style={{
            height: '420px',
            maxHeight: '100%',
            //width: '340px',
            maxWidth: '100%'
          }}
          entries={this.state.testScheduleEntries}
          rowHeight={20}
          rowMinutes={30}
          dayWidth={100/7}
          dayWidthUnit="%"
          timeWidth={'48px'}
          grabSize={6}
          minWeekWidth={800}
          snapTime={(value) => {
            return Math.round(value / 30) * 30;
          }}
          onChange={(entries) => {
            this.setState({
              testScheduleEntries: entries
            });
          }}
          itemContent={(entry, index) =>
            <div>
              <AccessControlPlanList
                accessControlGroups={[]}
                organization={this.props.organization}
                onChange={(planList) => {}}
                />
            </div>
          }
          /> */}
      </>
    );
  }

  public copyAPIKey(key: string): void {
    navigator.clipboard.writeText(key);
    toastSuccess('API key "' + key + '" copied to clipboard');
  }


  renderPaymentPlanList(paymentPlanList): ReactNode {
    if (!paymentPlanList)
      return <></>;
    
    if (!paymentPlanList.length) {
      return (
        <div className="text-center empty-group-list">
          The payment plan list is empty
        </div>
      );
    }
    
    return paymentPlanList.map((plan, index) => {
      return (
        <li key={plan.id}
          tabIndex={0}
          >
          <span className="sidebar-list-item text-truncate">
            {plan.name}
          </span>
        </li>
      );
    });
  }

  addPaymentIntegration(): void {
    ModalFactory.withDialog(ModalPaymentIntegration, 
    (paymentIntegrationModal) => {
      return paymentIntegrationModal.showDialog(1, null);

    });
  }

  viewIntegrationUsage(): void {
    ModalFactory.withDialog(ModalIntegrationUsage,
      (integrationUsageModal) => {
        return integrationUsageModal.showDialog();
      });
  }
  
}
