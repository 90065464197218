import * as React from 'react';
import { AuthService } from './components/services/auth.service';
import { BrowserRouter, Redirect, Route, RouteComponentProps, Router, Switch } from 'react-router-dom';
import { TreeSelect, Dropdown } from 'antd';

import 'semantic-ui-css/semantic.min.css';
import './bootstrap.min.css';
import 'antd/dist/antd.css';
import './util.css';
import './App.scss';

import { DashboardSheet } from './components/SheetDashboard';
import { DevicesSheet } from './components/SheetDevices';
import { PricingSheet } from './components/SheetPricing';
import { IntegrationSheet } from './components/SheetIntegration';
import { ReportingSheet } from './components/SheetReporting';
import { UsersSheet } from './components/SheetUsers';
import { SupportSheet } from './components/SheetSupport';

//import { BillingSheet } from './components/SheetBilling';

import SignInComponent from './components/signin.component';
import history from './components/services/history.service';

import { AccessControlSheet } from './components/SheetAccessControl';

import { 
  ChangeListener, ChangeOp, deleteOrganizationById, 
  getOrganizations, organizationListenerAdd, 
  organizationListenerRemove
} from './api';
import { ModalEditOrganization }
  from './components/modals/ModalEditOrganization';
import { ModalConfirm } from './components/modals/ModalConfirm';
import { Organization} from './api';
import { CustomizedToaster } from './components/CustomizedToaster';
import { UserProperty } from './components/UserProperty';
import { 
  HorizontalTab, 
  HorizontalTabContainer
} from './components/modals/HorizontalTabContainer';
import { ModalFactory } from './components/modals/Modal';
import { PureComponent, ReactNode, Suspense } from 'react';
import { 
  isDeveloper,
  isDemo,
  renderSpam,
  setStatePromise,
  setStatePromiseAtomic,
  toastError,
  toastSuccess
} from './components/shared/ui';
import { Drawer } from './components/Drawer';
import DrawerBackdrop from './components/DrawerBackdrop';

import './mobile.css';

import { xl8 } from './translations/i18n';
import { DeviceIcon } from './components/icons/DeviceIcon';
import { PricingIcon } from './components/icons/PricingIcon';
import { AccessControlIcon } from './components/icons/AccessControlIcon';
import { Notifications} from './components/Notifications';
import { DownArrowIcon } from './components/icons/DownArrowIcon';
import { SwychedSpinner } from './components/spinner.component';

import { ReportIcon } from './components/icons/ReportIcon';
import { AccountIcon } from './components/icons/AccountIcon';
import { DashboardIcon } from './components/icons/DashboardIcon';
import { BellIcon } from './components/icons/BellIcon';
import { LogoutIcon } from './components/icons/LogoutIcon';
import { MenuIcon } from './components/icons/MenuIcon';
import { CloseIcon } from './components/icons/CloseIcon';
import { CogIcon } from './components/icons/CogIcon';
import { WrenchIcon } from './components/icons/WrenchIcon';
import { TestSheet } from './components/SheetTest';
import { AttentionIcon } from './components/icons/AttentionIcon';

export interface AppRouteParams {
  tabslug: string;
}

interface AppProps extends RouteComponentProps<AppRouteParams> {
}

interface SwychedTreeSelectItem {
  title: ReactNode;
  value: string;
  children: SwychedTreeSelectItem[];
  key?: string;
}

interface AppState {
  selectedTabIndex: number;
  currentOrganization: Organization;
  treeData: SwychedTreeSelectItem[];
  busy: number;
  isAuthenticated: boolean | null;
  drawerOpen: boolean;
  notificationCount: number | null;
}



type SheetReadyCallback = () => void;

enum SpecialParentId {
  ROOT = -1,
  SIGNOUT = -2,
  ADDORG = -3,
  // DIVIDER = -4
}

const LazyDeveloperComponent = React.lazy(() => {
  return import('./components/developer.component');
});


export default class App extends PureComponent<AppProps, AppState> {
  public static getRoutes(): ReactNode {
    return <>
      <Route exact path="/:tabslug" component={App}/>
      <Route exact path="/" component={App}/>
    </>;
  }

  //private isAuthenticated: boolean | null = null;
  //private selectedTabIndex: number = 0;
  private orgById: { [id: number]: Organization } = {};
  private organizationListener: ChangeListener<Organization>;
  private organizationSelect = React.createRef<any>();
  private mainTabRef = React.createRef<HorizontalTabContainer>();
  
  constructor(props: AppProps) {
    super(props);

    let initialTabIndex = this.tabs.findIndex((tab) => {
      return tab.key === (this.props.match.params.tabslug ?? '')
    });
    if (initialTabIndex < 0)
      initialTabIndex = this.loadTabIndex(0);

    this.state = {
      selectedTabIndex: this.props.match.params.tabslug
        ? this.findTabIndexBySlug(this.props.match.params.tabslug)
        : this.loadTabIndex(0),
      currentOrganization: null,
      treeData: [],
      busy: 0,
      isAuthenticated: null,
      drawerOpen: false,
      notificationCount: null
    };    
  }
  
  private findTabIndexBySlug(tabslug: string): number {
    console.log('Finding tab by slug', tabslug);
    let index = this.tabs.findIndex((tab) => {
      return tab.key === tabslug;
    });
    return index;
  }

  private toggleClass() {
    const currentState = this.state.drawerOpen;
    this.setState({ drawerOpen: !currentState });
  }

  drawerToggleClickHandler(): void {
    this.setState({
      drawerOpen: !this.state.drawerOpen
    });
  }

  private drawerBackdropClickHandler(): void {
    this.setState({
      drawerOpen: false
    });
  }

  private drawerCloseClickHandler() {
    this.setState({
      drawerOpen: false
    });
  }

  private refreshOrganizations(
      selectionHint?: Organization | null): Promise<void> {
    
    return getOrganizations().then((orgs) => {
      //
      // The root node might not have -1 parentId
    
      let childrenOf: { [id: number]: Organization[] } = {};
      let orgById: { [id: number]: Organization } = {};

      // Make a fast organization lookup table by id
      orgs.forEach((org) => {        
        orgById[org.id] = org;
      });

      this.orgById = orgById;

      // Modify any orphan whose parent is missing to have -1 parentId
      orgs.forEach((org) => {
        if (!Object.prototype.hasOwnProperty.call(orgById, org.parentId))
          org.parentId = SpecialParentId.ROOT;
      });

      // Prepare lookup table of arrays of children, per parent
      orgs.forEach((org) => {        
        if (!Object.prototype.hasOwnProperty.call(childrenOf, org.parentId))
          childrenOf[org.parentId] = [];
      });

      // Add each child to its parent
      orgs.forEach((org) => {
        childrenOf[org.parentId].push(org);
      });

      let nodeLookup: { [id: number]: SwychedTreeSelectItem } = {};

      let todo: string[] = [
        '-1'
      ];

      while (todo.length) {
        let currentParent = todo.shift();
        
        if (!Object.prototype.hasOwnProperty.call(nodeLookup, currentParent)) {
          nodeLookup[currentParent] = {
            children: []
          };
        }

        let children = [];

        if (Object.prototype.hasOwnProperty.call(childrenOf, currentParent))
          children = childrenOf[currentParent];
        
        nodeLookup[currentParent].children = children.map((child) => {
          todo.push(child.id);

          let org = this.orgById[child.id];

          let img: React.ReactNode;

          let childNameSpan = (
            <span className="org-tree-menu-text">
              {child.name}
            </span>
          );

          if (org.icons.default) {
            img = <>
              <img className="org-select-icon" 
                alt=""
                src={org.icons.default}/> 
              {childNameSpan}
             </>;
          } else {
            img = <>
              <div className="org-select-icon-placeholder">
                {org.name[0].toLocaleUpperCase()}
              </div>
              {childNameSpan}
            </>;
          }

          let node: SwychedTreeSelectItem = {
            title: img,
            value: child.id,
            children: []
          };

          nodeLookup[child.id] = node;

          return node;
        });
      }

      let setOrg = this.state.treeData.length === 0;

      let rootList = nodeLookup[SpecialParentId.ROOT].children;

      // rootList.push({
      //   title: <>
      //     icon 
      //     {xl8('newOrganization')}
      //   </>,
      //   value: SpecialParentId.ADDORG,
      //   children: []
      // });

      // rootList.push({
      //   title: <>
      //     <hr/>
      //   </>,
      //   value: SpecialParentId.DIVIDER,
      //   children: []
      // });

      // // Add special menu options
      // rootList.push({
      //   title: <>
      //     <span className="logout-icon-container">
      //       <LogoutIcon width="24" height="24" fill="#aaa"/>
      //     </span>
      //     {xl8('signOut')} 
      //     {/* <UserProperty propertyName="email"/> */}
      //   </>,
      //   value: '' + SpecialParentId.SIGNOUT,
      //   children: [],
      //   key: 'bottom-spacer'
      // });
      
      // Need treeData to be picked up before setting value
      this.setState({
        treeData: nodeLookup[SpecialParentId.ROOT].children
      }, () => {
        if (selectionHint) {
          this.setOrganization(selectionHint.id);
        } else {
          let orgKeys = Object.keys(this.orgById);
          if (setOrg && orgKeys.length)
            this.setLastSelectedOrganization();
            //this.setOrganization(this.treeData[0].value);
        }
      });
    }).catch((err) => {
      toastError(err.message);
    });
  }

  private static readonly localStorageKeyLastOrg = 'dashboardLastOrg';

  loadLastOrg(): number | null {
    return +localStorage.getItem(App.localStorageKeyLastOrg);
  }

  saveLastOrg(organizationId: number): void {
    console.assert(App.localStorageKeyLastOrg);
    localStorage.setItem(App.localStorageKeyLastOrg, '' + organizationId);
  }

  setLastSelectedOrganization(): void {
    let lastOrg = this.loadLastOrg();
    if (!lastOrg || 
        !Object.prototype.hasOwnProperty.call(this.orgById, lastOrg)) {
      this.setOrganization(+this.state.treeData[0].value);
    } else {
      this.setOrganization(lastOrg);
    }
  }

  adjBusy(amount: number): Promise<void> {
    return setStatePromiseAtomic<AppState, App>(this, 
      (prevState: AppState) => {
        return {
          busy: prevState.busy + amount
        };
      });
  }

  // notifyTabsOrganizationChanged(organizationId: number): void {
  //   this.adjBusy(1).then(() => {
  //     let promises = Object.keys(this.sheets).map((key) => {
  //       return new Promise<void>((resolve, reject) => {
  //         this.whenSheetReady(key, () => {
  //           let sheet: OrganizationChangeListener = this.sheets[key];
  //           if (sheet)
  //             resolve(sheet.organizationChanged(this.orgById[organizationId]));
  //           else
  //             resolve();
  //         });
  //       });
  //     });
  
  //     return Promise.all(promises);
  //   }).then(() => {
  //     return this.adjBusy(-1);
  //   }).then(() => {
  //       console.log('organization change complete');
  //   });
  // }

  componentDidMount(): void {
    let authService = AuthService.getInstance();

    setStatePromise<AppState, App>(this, {
      isAuthenticated: authService.isAuthenticated()
    }).then(() => {
      if (!this.state.isAuthenticated)
        return undefined;
  
      //let tenant = authService.getCurrentTenant();
      
      let orgRefresh = this.refreshOrganizations();
      // this.props.whitelabel.organizationId);    
  
      this.organizationListener = organizationListenerAdd(
        (organization: Organization, op: ChangeOp) => {
          console.log('organization listener fired');
          this.refreshOrganizations(organization);
        });
  
      return Promise.all([orgRefresh]);
    }).then(() => {
      console.log('App mount complete');
    }).catch((err) => {
      toastError(err.message);
    });
  }

  componentWillUnmount(): void {
    organizationListenerRemove(this.organizationListener);
  }

  private sheets: { [name: string]: OrganizationChangeListener } = {
    dashboard: null,
    reporting: null,
    devices: null,
    // billing: null,
    accessControl: null,
    users: null
  };

  private setSheet(name: string, sheet: OrganizationChangeListener) {
    this.sheets[name] = sheet;

    let callbacks = this.sheetReadyCallbacks[name] || [];
    callbacks.splice(0, callbacks.length).forEach((callback) => {
      callback();
    });
  }

  private reportingBusyRef = React.createRef<SwychedSpinner>();

  private tabs: HorizontalTab[] = [
  {
    key: 'dashboard',
    icon: <DashboardIcon width={'24'} height={'24'} fill="#555" />,
    titleText: xl8('dashboard'),
    unloadImmediate: true,
    body: (tab: HorizontalTab, visible: boolean) => <DashboardSheet
        ref={(sheet) => this.setSheet('dashboard', sheet)}
        organization={this.state.currentOrganization}
        visible={visible}
      />
  }, 
  {
    key: 'devices',
    icon: <DeviceIcon width={'24'} height={'24'} fill="#555" />,
    titleText: xl8('devices'),
    body: (tab: HorizontalTab, visible: boolean) => <DevicesSheet
        ref={(sheet) => this.setSheet('devices', sheet)}
        organization={this.state.currentOrganization}
        visible={visible}
      />
  }, 
  {
    key: 'reporting',
    icon: <ReportIcon width={'24'} height={'24'} fill="#555"/>,
    titleText: xl8('reporting'),
    titleRender: (text) => <>
      {text}
      <SwychedSpinner ref={this.reportingBusyRef} busy={0}/>
    </>,
    unloadDisabled: true,
    body: (tab: HorizontalTab, visible: boolean) => <ReportingSheet
        ref={(sheet) => this.setSheet('reporting', sheet)}
        organization={this.state.currentOrganization}
        busy={this.reportingBusyRef}
        visible={visible}
      />
  },
  // {
  //   key: 'pricing',
  //   icon: <PricingIcon width={'24'} height={'24'} fill="#555"/>,
  //   titleText: xl8('pricing'),
  //   visible: isDeveloper(),
  //   body: (tab: HorizontalTab, visible: boolean) => <PricingSheet
  //       ref={(sheet) => this.setSheet('pricing', sheet)}
  //       organization={this.state.currentOrganization}
  //       visible={visible}
  //     />
  // },
  {
    key: 'integrations',
    icon: <CogIcon width={'22'} height={'24'} fill="#555"/>,
    titleText: xl8('integrations'),
    body: (tab: HorizontalTab, visible: boolean) => <IntegrationSheet
        ref={(sheet) => this.setSheet('integration', sheet)}
        organization={this.state.currentOrganization}
        visible={visible}
      />
  },
   {
    key: 'accessControl',
    icon: <AccessControlIcon width={'24'} height={'24'} fill="#555" />,
    titleText: xl8('accessControl'),
    body: (tab: HorizontalTab, visible: boolean) => <AccessControlSheet
      ref={(sheet) => this.setSheet('accessControl', sheet)}
      organization={this.state.currentOrganization}
      visible={visible}
    />
  }, {
    key: 'users',
    icon: <AccountIcon width={'24'} height={'24'} fill="#555" />,
    titleText: xl8('users'),
    body: (tab: HorizontalTab, visible: boolean) => <UsersSheet
      ref={(sheet) => this.setSheet('users', sheet)}
      organization={this.state.currentOrganization}
      visible={visible}
    />
  }, 
  // {
  //   key: 'support',
  //   icon: <WrenchIcon width={'24'} height={'24'} fill="#555" />,
  //   titleText: xl8('support'),
  //   visible: isDeveloper(),
  //   body: (tab: HorizontalTab, visible: boolean) => <SupportSheet
  //     ref={(sheet) => this.setSheet('support', sheet)}
  //     organization={this.state.currentOrganization}
  //     visible={visible}
  //   />
  // },
  // {
  //   key: 'test',
  //   icon: <AttentionIcon width={'24'} height={'24'} fill="#555" />,
  //   titleText: 'test',
  //   body: (tab: HorizontalTab, visible: boolean) => <TestSheet
  //     ref={(sheet) => this.setSheet('support', sheet)}
  //     organization={this.state.currentOrganization}
  //     visible={visible}
  //   />
  // }
  // , {
  //   key: 'billing',
  //   icon: 'paid',
  //   title: 'Billing',
  //   body: () => <BillingSheet
  //       ref={(sheet) => this.setSheet('billing', sheet)}
  //     />
  // }
  ];

  
  private sheetReadyCallbacks: { [name: string]: SheetReadyCallback[] } = {};

  private whenSheetReady(name: string, callback: SheetReadyCallback) {
    if (this.sheets[name]) {
      callback();
      return;
    }

    if (!this.sheetReadyCallbacks[name])
      this.sheetReadyCallbacks[name] = [];
    this.sheetReadyCallbacks[name].push(callback);
  }

  render(): JSX.Element {
    renderSpam('App');
    if (this.state.isAuthenticated === false)
      return <Redirect to="/login"/>;

    if (this.state.isAuthenticated === null) {
      // Show busy
      return <>
        <div className="spinner-container">
          <div className="fa-3x spinner">
            <i className="fa fa-circle-o-notch fa-spin swyched-spinner"></i>
          </div>
        </div>
      </>;
    }

    
    let drawerBackdrop;
    if(this.state.drawerOpen){
      drawerBackdrop = <DrawerBackdrop 
        close={() => this.drawerBackdropClickHandler()} />;
     }

    let notificationPanel = (
      <Notifications 
        organization={this.state.currentOrganization}
        countChanged={(notificationCount) => {
          this.setState({
            notificationCount: notificationCount
          });
        }}/>
      );
      

    return <>
      <div className="app">
        <div className="wait-indicator"></div>
        {
          (this.state.busy > 0) 
            ? <>
              <div className="spinner-container">
                <div className="fa-3x spinner">
                  <i className="fa fa-circle-o-notch fa-spin swyched-spinner"></i>
                </div>
              </div>
            </>
            : <></>
        }
          
        <div className="app-container">
          <div className="header-container">
            <button type="button" className="mobile-nav-toggle"
              onClick={(event) => {
                this.drawerToggleClickHandler();
              }}> <MenuIcon width="25px" height="35px" fill="#555"/>
            </button>
            <Drawer title="" open={this.state.drawerOpen}>
              <div className="close-drawer-btn"
                  onClick={(event) => {
                    this.drawerCloseClickHandler();
                  }}>
                <CloseIcon width="24px" height="24px" fill="#ccc"/>
              </div>
              <HorizontalTabContainer tabs={this.tabs}
                vertical={true}
                tabChanged={(newTabIndex) => {
                  this.mainTabRef.current.setTabIndex(newTabIndex);
                }}
                // forceTabIndex={this.state.selectedTabIndex}
                tabClicked={(newTabIndex) => {
                  this.setState({
                    drawerOpen: false
                  });
                }}
              />
            </Drawer>

            { drawerBackdrop }
            
            {/* {this.userEmail} */}
            {/* <div className="user-avatar">
              <i className="user-status"></i>
              <div className="avatar-icon">M</div>
            </div> */}
            {/* <NotificationDrawer
              organization={this.state.currentOrganization}
              open={this.state.drawerOpen}
                openChanged={(newOpen) => {
                  this.setState({
                    drawerOpen: newOpen
                  });
                }}
              countChanged={(notificationCount) => {
                this.setState({
                  notificationCount: notificationCount
                });
              }}/> */}
            
              {/* <TreeSelect
                treeData={[{
                  title: 'English',
                  value: 'en',
                  key: 'en'                  
                }, {
                  title: 'Français',
                  value: 'fr',
                  key: 'fr'                  
                }]}
                /> */}

            <div className="org-select-container">
              <TreeSelect 
                suffixIcon={<DownArrowIcon width="20" height="20" fill="#555" />}
                value={this.state.currentOrganization?.id || ''}
                ref={this.organizationSelect}
                placeholder="Select Organization"
                treeData={this.state.treeData}
                style={{ width: '100%'}}
                
                dropdownRender={(menu) => {
                  return <>
                    <div className="org-tree-menu-body"
                    >
                    <div className="org-info-container"
                      >
                      <img className="org-select-icon" 
                        alt="" 
                        src={this.state.currentOrganization?.icons.default} />
                      <span>
                        {this.state.currentOrganization?.name}
                      </span>
                      <div className="user-email">
                        {xl8('signedInAs')}: <UserProperty propertyName="email"/>
                      </div>
                    </div>
                    {menu}
                    <div 
                      className="ant-select-tree-node-content-wrapper
                        ant-select-tree-node-content-wrapper-normal
                        custom-sticky-signout"
                      onClick={(event) => {
                        AuthService.getInstance().signOut();
                      }}>
                      <span className="logout-icon-container">
                        <LogoutIcon width="24" height="24" fill="#aaa"/>
                      </span>  
                      Sign out
                    </div>
                  </div>
                  </>;
                }}
                dropdownStyle={{
                  maxHeight: '444px',
                  overflow: 'auto',
                  width: '438px',
                  borderRadius: '5px',
                  top: '50px',
                  marginTop: '50px',
                  paddingTop: '20px',
                  position: 'relative'
                }}
                treeDefaultExpandAll
                listHeight={444}
                dropdownAlign={{overflow: { adjustY: 0, adjustX: 180 }}}
                dropdownMatchSelectWidth={false}
                onSelect={(value, option) => {
                  switch (+option.value) {
                  default:
                    if (this.state.currentOrganization.id !== +option.value)
                      this.setOrganization(+option.value);
                    break;

                  // case SpecialParentId.DIVIDER:
                  //   break;

                  case SpecialParentId.SIGNOUT:
                    AuthService.getInstance().signOut();
                    break;

                  case SpecialParentId.ADDORG:
                    // Hack, edit current org
                    this.onEditOrganization(this.state.currentOrganization);
                    break;

                  }
                }}
                />        
            </div>
            <div className="hidden">
              <Notifications 
                organization={this.state.currentOrganization}
                countChanged={(notificationCount) => {
                  this.setState({
                    notificationCount: notificationCount
                  });
                }}/>
            </div>

            <Dropdown 
              overlay={notificationPanel} 
              placement="bottomLeft" trigger={['click']}
              >

              <div className="notification-btn notification-bell-icon"
                  data-count={this.state.notificationCount || null}>
                <span className="notification-bell-icon">
                  <BellIcon width="36" height="36" fill="#555" />
                </span>
              </div>
            </Dropdown>
          </div>

          <HorizontalTabContainer
            ref={this.mainTabRef}
            tabs={this.tabs}
            containerClassName={this.state.drawerOpen ? 'mobile-menu-open': null} 
            tabContainerClassName="top-nav"
            invisibleTabsDisappear={false}
            lazy={true}
            unloadTimeoutMs={10000}
            storageKey="topNavTabIndex"
            enableNav={true}
            // forceTabIndex={this.state.selectedTabIndex}
            tabChanged={(newTabIndex) => {
              console.log('App got tab change to', newTabIndex);
              if (newTabIndex !== this.state.selectedTabIndex) {
                this.setState({
                  selectedTabIndex: newTabIndex
                });
              }
            }}
            />
        </div>
      </div>
      <ModalFactory/>
      <CustomizedToaster/>
    </>;
  }
  
  setOrganization(organizationId: number): void {
    this.setState({
      currentOrganization: this.orgById[organizationId]
    }, () => {
      this.saveLastOrg(this.state.currentOrganization.id);
      //this.notifyTabsOrganizationChanged(organizationId);
    });
  }

  private static readonly dashboardTabIndexKey = 'dashboardTabIndex';

  private loadTabIndex(defaultIndex: number): number {
    let sessionIndex = sessionStorage.getItem(App.dashboardTabIndexKey);
    let localIndex = localStorage.getItem(App.dashboardTabIndexKey);

    if (sessionIndex !== null)
      return +sessionIndex;
    
    if (localIndex !== null)
      return +localIndex;
    
    return defaultIndex;
  }

  private saveTabIndex(index: number): void {
    console.assert(App.dashboardTabIndexKey);
    sessionStorage.setItem(App.dashboardTabIndexKey, '' + index);
    localStorage.setItem(App.dashboardTabIndexKey, '' + index);
  }

  private onAddOrganization(): Promise<Organization | null> {
    return ModalFactory.withDialog(ModalEditOrganization, 
    (editOrganizationModal) => {
      return editOrganizationModal.showDialog(null)
      .then((result) => {
        if (!result)
          return null;
  
        toastSuccess('Created new org ' + result.organization.name);
        return result.organization;        
      }).catch((err) => {
        toastError(err.message);
        return null;
      });
    });
  }


  onDeleteOrganization(organization: string): void {
    let organizationId = this.state.currentOrganization.id;

    ModalConfirm.showIndirect({
      message: 'Are you sure you want to delete organization?',
      okCallback: () => {
        return deleteOrganizationById(organizationId).then((result) => {
          toastSuccess('Org deleted');
          return true;
        }).catch((err) => {
          toastError('Error deleting organization ' + err.message);
          return null;
        });
      }
    }).then((result) => {
      if (result?.ok)
        this.refreshOrganizations();
    });
  }

  onEditOrganization(organization: Organization): void {
    ModalFactory.withDialog(ModalEditOrganization, 
    (editOrganizationModal) => {
      return editOrganizationModal.showDialog(organization)
      .then((result) => {
        if (!result)
          return Promise.resolve();
        return this.refreshOrganizations(this.state.currentOrganization);
      });
    });
  }

  // onEditFolder(folder: Folder): void {
  //   ModalFactory.withDialog(ModalEditFolder, 
  //   (editFolderModal) => {
  //     return editFolderModal.showDialog(folder)
  //     .then((result) => {
  //       if (!result)
  //         return Promise.resolve();
  //       return this.refreshOrganizations(this.state.currentOrganization);
  //     });
  //   });
  // }
}

export interface OrganizationChangeListener {
  organizationChanged(org: Organization): Promise<void>;
}


export function swychedRouterFactory(historyImpl = history): JSX.Element { 
  return <React.StrictMode>
    <BrowserRouter>
      <Switch>
        <Route path="/login" component={SignInComponent}/>
        <Route path="/developer" render={() => {
          return (
            <Suspense 
                fallback={
                  <>
                    <SwychedSpinner busy={1}/> Loading IDE
                  </>
                }>
              <LazyDeveloperComponent/>
            </Suspense>
          );
        }}/>
        
        {App.getRoutes()}
        <Route path="">404</Route>
      </Switch>
    </BrowserRouter>
  </React.StrictMode>;
}
