import React, { Component, ReactNode } from "react";
import { uniqueId } from "./shared/ui";

export interface FormSwitchProps {
  label: ReactNode;
  value: boolean;
  disabled?: boolean;
  className?: string;
  onChange?: (newValue: boolean) => void;
}

interface FormSwitchState {  
}

export class FormSwitch 
    extends Component<FormSwitchProps, FormSwitchState> {
  private uniq = uniqueId() + 'label';

  constructor(props: FormSwitchProps) {
    super(props);
    this.state = {
    };
  }

  render(): ReactNode {
    return (
      <>
        <div className="form-check form-switch">
          <input type="checkbox"
              disabled={this.props.disabled}
              id={this.uniq} 
              className={"form-check-input " +
                (this.props.className || '')}
              checked={this.props.value}              
              onClick={(event) => {
                event.stopPropagation();
              }}
              onChange={(event) => {
                if (this.props.onChange)
                  this.props.onChange(event.target.checked);
              }} />
        </div>
        <label className="form-switch-label"
          htmlFor={this.uniq}>{this.props.label}</label>
      </>
    );
  }
}
