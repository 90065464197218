import * as React from 'react';
import { Component, ReactNode } from "react";
import {
  Connector, ConnectorStatus, DeviceStatusString,
  deleteDeviceById,
  deleteSiteById, Device, getAllSiteDevices,
  postDeviceBySite, postSiteByOrganization, Site, SiteDevices,
  getDevicesBySite,
  ConnectorType, Organization,
  getDeviceStatuses, DeviceStatusesResponse,
  restartDeviceById, startSession,
  checkSession, stopSession, CheckSessionExistsResponse,
  CheckSessionNotExistsResponse,
  patchDevice,
  virtualDeviceName,
  ChargerType,
  RealtimePowerResponse,
  RealtimePowerPortConnectorSample,
  getRealtimePower
} from '../api';
import { ModalEditSite } from './modals/ModalEditSite';
import { ModalStartSession } from './modals/ModalStartSession';
import { ModalConfirm } from './modals/ModalConfirm';
import { ModalEditDevice } from './modals/ModalEditDevice';
import { Dropdown } from 'semantic-ui-react';
import { OrganizationChangeListener } from '../App';
import {
  formatDate, isDemo, renderSpam, sequentialPromiseMap,
  setStatePromise, toastError, toastSuccess
} from './shared/ui';
import { ModalFactory } from './modals/Modal';
import { PureComponent } from 'react';
import { ModalImportDevices } from './modals/ModalImportDevices';
import './SheetDevices.css';
import { SwychedSpinner } from './spinner.component';
import { xl8, xl8e } from '../translations/i18n';
import { Popover } from 'antd';

//import { SwychedTooltip } from './SwychedTooltip';

import "./SwychedTooltip.scss";

import { DeviceIcon } from './icons/DeviceIcon';
import { ChargerIcon } from './icons/ChargerIcon';
import { HubIcon } from './icons/HubIcon';
import { PlayIcon } from './icons/PlayIcon';
import { Type1Icon } from './icons/deviceType/Type1Icon';
import { Type2Icon } from './icons/deviceType/Type2Icon';
import { CCS1Icon } from './icons/deviceType/CCS1Icon';
import { CCS2Icon } from './icons/deviceType/CCS2Icon';
import { CHAdeMOIcon } from './icons/deviceType/CHAdeMOIcon';
import { EllipsisIcon } from './icons/EllipsisIcon';
import { PlusIcon } from './icons/PlusIcon';
import { RefreshIcon } from './icons/RefreshIcon';
import { CogIcon } from './icons/CogIcon';
import { RestartIcon } from './icons/RestartIcon';
import { ImportIcon } from './icons/ImportIcon';
import { NoSitesIcon } from './icons/NoSitesIcon';
import { NoDevicesIcon } from './icons/NoDevicesIcon';
import { DeleteIcon } from './icons/DeleteIcon';
import { AttentionIcon } from './icons/AttentionIcon';
import { AlertIcon } from './icons/AlertIcon';
import { SwychedPopover } from './SwychedPopover';

import { 
  TransitionGroup, CSSTransition 
} from "react-transition-group";
export interface DeviceDetail {
  deviceId: number;
  deviceName: string;
}

interface DragPayload {
  uuid: string;
  device: Device;
}

interface DevicesSheetState {
  organization: Organization;
  view: string;
  siteDevices: SiteDevices;
  statusesResponse: DeviceStatusesResponse;
  realtimePower: RealtimePowerResponse;
  backgroundRefreshPending: boolean;
  selectedSite: Site;
  expandedPower: Set<string>;
}

export interface DevicesSheetProps {
  organization: Organization;
  visible: boolean;
}

export interface SiteInfo {
  id: number;
  name: string;
  site: Site;
}

export class DevicesSheet
  extends Component<DevicesSheetProps, DevicesSheetState>
  implements OrganizationChangeListener {
  private realStatus = true;
  // null=not set yet, undefined=unmounted
  private updateInterval: NodeJS.Timeout | null | undefined = null;
  private static readonly AutoRefreshInterval = 30000;
  private unmounted: boolean = true;

  static readonly dragContentType: string = 'application/json';

  constructor(props: DevicesSheetProps) {
    super(props);

    this.state = {
      organization: props.organization,
      view: 'card',
      siteDevices: {
        sites: [],
        deviceListBySite: Object.create(null)
      },
      statusesResponse: null,
      realtimePower: null,
      backgroundRefreshPending: false,
      selectedSite: null,
      expandedPower: new Set<string>()
    };
  }

  organizationChanged(org: Organization): Promise<void> {
    console.log('got organizationChanged interface call');
    return Promise.resolve();
  }

  // organizationChanged(org: Organization): Promise<void> {
  //   return new Promise<void>((resolve, reject) => {
  //     this.setState({
  //       organization: org
  //     }, () => {
  //       resolve(this.updateDevices(true));
  //     });
  //   });
  // }

  componentDidMount(): void {
    console.assert(this.unmounted);
    this.unmounted = false;

    if (this.state.organization)
      this.updateDevices(true);
  }

  private startIntervalIfNotStarted() {
    if (this.updateInterval === null) {
      this.updateInterval = setInterval(() => {
        this.setState({
          backgroundRefreshPending: true
        });
        this.updateDevices(false).then(() => {
          this.setState({
            backgroundRefreshPending: false
          });
        }).catch(() => {
          this.setState({
            backgroundRefreshPending: false
          });
        });
      }, DevicesSheet.AutoRefreshInterval);
    }
  }

  private clearIntervalIfStarted() {
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
      this.updateInterval = null;
    }
  }

  private cancelIntervalForever() {
    this.clearIntervalIfStarted();
    this.updateInterval = undefined;
  }

  componentWillUnmount(): void {
    console.assert(!this.unmounted);
    this.cancelIntervalForever();
    this.unmounted = true;
  }

  componentDidUpdate(
    oldProps: DevicesSheetProps,
    oldState: DevicesSheetState): void {
    if (this.props.organization?.id !== oldProps.organization?.id) {
      console.log('organization changed by props'); //this.updateDevices(false);
      this.setState({
        selectedSite: null,
        organization: this.props.organization
      });
    }

    if (oldState.organization?.id !== this.state.organization?.id)
      this.updateDevices(true);

    if (oldState.siteDevices !== this.state.siteDevices ||
        oldState.selectedSite !== this.state.selectedSite) {
      // Make sure the location filter makes sense
      let siteFilterOk = this.state.selectedSite &&
        this.state.siteDevices?.sites.find((candidate) => {
          return candidate.id === this.state.selectedSite.id;
        });
      
      if (!siteFilterOk && this.state.selectedSite !== null) {
        this.setState({
          selectedSite: null
        });
      }
    }

    // Select first site
    // if (this.state.siteDevices?.sites && !this.state.selectedSite) {
    //   this.setState({
    //     selectedSite: this.state.siteDevices.sites[0]
    //   });
    // }
  }

  private powerUpdateRunning: boolean = false;

  updateDevices(eagerRefresh: boolean): Promise<void> {
    this.clearIntervalIfStarted();

    console.assert(this.state.organization, 'No organization!');
    if (!this.state.organization) {
      console.log('no organization id?!');
      return Promise.resolve();
    }

    let organization = this.state.organization;

    let siteDevicesPromise: Promise<SiteDevices>;
    siteDevicesPromise = getAllSiteDevices(organization.id)
      .then((siteDevices) => {
        if (organization.id !== this.state.organization.id)
          return null;
        if (this.unmounted)
          return null;
        
        // If not eager, then wait until we have both to update state
        if (!eagerRefresh)
          return siteDevices;
        
        // Eager, update device list ASAP
        return setStatePromise<DevicesSheetState, DevicesSheet>(this, {
          siteDevices
        }).then(() => siteDevices);
      }).catch((err) => {
        toastError('Device list update failed: ' + err.message);
        return null;
      });

    let statusPromise = getDeviceStatuses(
      organization.id, this.state.statusesResponse);

    let powerPromise: Promise<RealtimePowerResponse>;
    if (!this.powerUpdateRunning) {
      this.powerUpdateRunning = true;
      powerPromise = getRealtimePower(organization.id);

      powerPromise.then((realtimePower) => {
        this.powerUpdateRunning = false;

        this.setState({
          realtimePower
        });
      }).catch((err) => {
        toastError('Power update failed: ' + err.message);
      });
    } else {
      powerPromise = Promise.resolve(null);
    }
    return Promise.all([
      siteDevicesPromise,
      statusPromise
    ]).then(([siteDevices, statusData]) => {
      if (organization.id !== this.state.organization.id)
        return [null, null];
      if (!eagerRefresh) {
        return setStatePromise<DevicesSheetState, DevicesSheet>(this, {
          siteDevices: siteDevices
        }).then(() => {
          return Promise.all([
            siteDevices,
            statusData
          ]);
        });
      }

      return Promise.all([
        siteDevices,
        statusData
      ]);
    }).then(([siteDevices, statusData]) => {
      if (!siteDevices)
        return null;

      if (statusData)
        this.mergeConnectorStatuses(siteDevices, statusData);

      return setStatePromise<DevicesSheetState, DevicesSheet>(this, {
        siteDevices: { ...siteDevices },
        statusesResponse: statusData
      });
    }).then(() => {
      return powerPromise;
    }).then(() => {      
    }).catch((err) => {
      toastError('Refresh failed: ' + err.message);
    }).then(() => {      
      this.startIntervalIfNotStarted();
    });

    // return getAllSiteDevices(organization.id)
    // .then((siteDevices) => {
    //   this.setState({
    //     siteDevices: siteDevices
    //   });
    //   return siteDevices;
    // }).then((siteDevices) => {
    //   if (!siteDevices)
    //     return;
    //   return this.updateStatus(organization, siteDevices, eagerRefresh)
    //   .catch((err) => {
    //     toastError('Status update failed: ' + err.message);
    //   });
    // }).catch((err) => {
    //   toastError('Device list update failed: ' + err.message);
    // }).then(() => {        
    // });
  }

  // updateStatus(organization: Organization, 
  //     siteDevices: SiteDevices, eagerRefresh: boolean): Promise<void> {
  //   // Update display ASAP with devices, let status update after it comes in
  //   if (eagerRefresh) {
  //     this.setState({
  //       organization: organization,
  //       siteDevices: siteDevices
  //     });
  //   }

  //   this.startIntervalIfNotStarted();

  //   getInstantResponse(organization.id);

  //   let status: Promise<DeviceStatusesResponse> =
  //     this.statusForEvseIds(organization.id, []);//evseIds);

  //   return Promise.all([siteDevices, status])
  //   .then(([siteDevices, statuses]) => {
  //     this.mergeConnectorStatuses(siteDevices, statuses);

  //     this.setState({
  //       organization: organization,
  //       siteDevices: siteDevices
  //     });
  //   });
  // }

  // statusForEvseIds(id: string, 
  //     evseIds: any[]): Promise<DeviceStatusesResponse> {
  //   let status = getDeviceStatuses(id, evseIds);
  //   return status;
  // }

  // deviceIdsFromSiteDevices(siteDevices: SiteDevices) {
  //   return Object.keys(siteDevices.deviceListBySite)
  //   .reduce((evseIds, siteId) => {
  //     siteDevices.deviceListBySite[siteId].forEach((evse) => {
  //       evseIds.push(evse.id);
  //     });
  //     return evseIds;
  //   }, []);
  // }

  mergeConnectorStatuses(siteDevices: SiteDevices,
    statuses: DeviceStatusesResponse): void {
    // For each site
    let siteIds = Object.keys(siteDevices.deviceListBySite);

    siteIds.forEach((siteId: string) => {
      // Get the device list for the site
      let deviceList: Device[] = siteDevices.deviceListBySite[siteId];

      let newDeviceList: Device[] = [];

      // For each device at the site
      deviceList.filter((device) => {
        return !device.virtualDeviceIndex;
      }).forEach((device, index) => {
        // Get the status list for each connector of the device
        let deviceStatus = statuses.devices[device.id];
        let connectors = device.connectors;

        if (!deviceStatus) {
          // Synthesize hub devices even without status information
          deviceStatus = [];
          for (let i = 0; i < Math.max(1, connectors.length); ++i)
            deviceStatus[connectors[i]?.portConnectorId || 0] = null;
        }

        if (connectors.length > 0) {
          connectors.forEach((connector, index) => {
            let portConnectorId = connector.portConnectorId;
            let timestampStatus = deviceStatus[portConnectorId];
            newDeviceList.push({
              ...device,
              status: timestampStatus?.status ?? null,
              virtualDeviceIndex: index
            });
          });
        } else {
          newDeviceList.push({
            ...device,
            status: null,
            virtualDeviceIndex: -1
          });
        }
      });

      // Clear deviceList and copy newDeviceList into deviceList
      deviceList.splice(0, deviceList.length);
      newDeviceList.forEach((virtualDevice) => {
        deviceList.push(virtualDevice);
      });
      
      //console.log('merged statuses, site=', siteId, deviceList);
    });

  }

  render(): JSX.Element {
    if (!this.props.visible)
      return <div>Hidden tab panel</div>;
    renderSpam('SheetDevices');
    return (
      <>
        <div className="row sheet-header">
          <div className="page-title-container col-lg-9">
            <h2 className="sheet-title">
              {xl8('devices')}
            </h2>
            <div className="sheet-subtitle">
              {xl8('mySitesAndStations')}
            </div>
          </div>
          {/* <div className="sheet-control-container col-lg-3">
            <button className="btn pull-right refresh-status-btn"
              type="button"
              disabled={!this.state.organization}
              onClick={(event) => this.onRefresh(event)}>
              <span>
                <RefreshIcon width="24" height="24" fill="#aaa" />
              </span>

            </button>
            <button className="btn btn-white pull-right import-devices-btn"
                type="button" 
                disabled={!this.state.organization}
                onClick={(_) => this.onImportDevices()}>
              Import Devices
            </button>
          </div> */}
        </div>


        <div className="row clear">
          <div className="col-lg-3 col-md-3">
            <div className="device-select">
              {this.renderSiteSelect()}
            </div>
            <div className="left-sidebar-list">
              {/* <div className="input-group search-filter">
                <input type="search" className="form-control"
                  placeholder={xl8('search')} aria-label="Search"
                  // value="value"
                  onChange={(event) => {
                  }}
                  aria-describedby="search-addon" />
                <button className="btn btn-outline"
                  type="button"
                  onClick={(event) => {

                  }}>
                  <SearchIcon width="24" height="24">

                </button>
              </div> */}
              {this.renderSiteList()}
            </div>
          </div>
          <div className="col-lg-9 col-md-9">
            <div className="site-container" 
              // onClick={SwychedPopover.makeDismissPopoversOnClick(this, 
              //   'expandedPower', () => new Set<string>())}
              >
              {/* <div className="card-body"> */}
              {/* <div className="site-breadcrumb">
                  <ul>
                    <li>My Sites</li>
                    <li>
                    >
                    </li>
                    <li>Swyched Office</li>
                  </ul>
                </div> */}
              {this.deviceRows()}
              {/* </div> */}
            </div>
          </div>
        </div>
        {/* <Dropdown
          icon='cog horizontal' 
          className="btn btn-primary pull-right">
          <Dropdown.Menu className="left pointing">
            <Dropdown.Item icon='refresh' text="Refresh Status"
              onClick={() => this.onRefresh(event)}
              />
            <Dropdown.Item icon='import' text="Import Devices"
              onClick={(_) => this.onImportDevices()} />
          </Dropdown.Menu>
        </Dropdown> */}

        {/* <div hidden> 
          <label className="toolbar-radio">
            <input name="view" type="radio"
              ref={(ref) => { this.cardRadio = ref}}
              onInput={this.viewChanged.bind(this, 'card')}/>
            <i className="fa fa-th"></i>
          </label>
          <label className="toolbar-radio">
            <input name="view" type="radio"
              ref={(ref) => { this.cardRadio = ref}}
              onInput={this.viewChanged.bind(this, 'detail')}/>
            <i className="fa fa-list"></i>
          </label>
        </div> */}
      </>
    );
  }

  onRefresh(event: React.MouseEvent<HTMLButtonElement, MouseEvent>)
    : Promise<void> {
    if (!this.state.siteDevices)
      return Promise.resolve();

    return setStatePromise<DevicesSheetState, DevicesSheet>(this, {
      backgroundRefreshPending: true
    }).then(() => {
      return this.updateDevices(true);
    }).then(() => {
      return setStatePromise<DevicesSheetState, DevicesSheet>(this, {
        backgroundRefreshPending: false
      });
    });
  }

  private viewChanged(newView: string) {
    this.setState({
      view: newView
    });
  }

  private deviceRows(): ReactNode | null {
    switch (this.state.view) {
      case 'card':
        return this.cardRows();

      // case 'detail':
      //   return this.detailRows();

      default:
        return null;
    }
  }

  // private detailRows(): JSX.Element[] {
  //   return this.location.map((location) => {
  //     return this.detailRow(location);
  //   });
  // }

  // private detailRow(location: DeviceLocation): JSX.Element {
  //   return (
  //     <div
  //         key={location.locationId}
  //         className="row">
  //       Detail
  //     </div>
  //   );
  // }

  private static readonly dragUuid = '54bd1004-c6aa-4a4b-b3c1-fb97ff11882d';

  private hubItem(hub: Device, container: Device[]): ReactNode {
    return null;
  }

  private cardRows(): ReactNode {
    let i: number;
    let rows: Site[][] = [];
    let row: Site[] = [];
    let siteDevices = this.state.siteDevices;

    if (!siteDevices?.sites?.length) {
      return (
        <div>
          <div className="empty-container-overlay text-center">
            <NoSitesIcon width={'96'} height={'96'}/>
            <h3 className="empty-state-header">
              {xl8('emptySiteHeader')}
            </h3>
            <p className="empty-state-subheader"> 
              {xl8('emptySiteText')}
            </p>
          </div>
        </div>
      );
    }

    let displayedSites = siteDevices.sites.filter((site) => {
      return !this.state.selectedSite ||
        this.state.selectedSite.id === site.id;
    });

    for (i = 0; i < displayedSites.length; ++i) {
      if (!(row.length & 3)) {
        row = [];
        rows.push(row);
      }
      row.push(displayedSites[i]);
    }

    return rows.map((sites, index) => {
      return (
        // <div 
        //   key={'row_' + index}
        //   className="row">
          sites.map((site, i) => {
            return this.renderSite(site,
              siteDevices.deviceListBySite[site.id] || [], i);
          })
        //</div>
      );
    });
  }

  private renderSite(site: Site, devices: Device[],
    index: number): JSX.Element {

    return (
      <div key={site.id} className="card">
        <div className="card-body" onClick={
            SwychedPopover.makeDismissPopoversOnClick(this, 
              'expandedPower', () => new Set<string>())}>
          <div className="site-header-container">
            <div className="add-device-btn-container">
              <div className="site-actions">
                <Dropdown data-tip="Site Options"
                  className="pull-right btn-action"
                  icon={<EllipsisIcon width="28" height="28" fill="#C1C5C8"/>}>
                  <Dropdown.Menu className="left pointing">
                    <Dropdown.Item 
                      icon={<CogIcon width="21.5" height="21.5" fill="rgb(193, 197, 200)"/>}
                      text={xl8('editSiteCommand')}
                      onClick={(event) => {
                        this.onEditSite(site);
                      }} />
                    <Dropdown.Item 
                      icon={<DeleteIcon width="24" height="24" fill="rgb(193, 197, 200)"/>}
                      text={xl8('deleteSiteCommand')}
                      onClick={(event) => {
                        this.onDeleteSite(site);
                      }} />
                    <Dropdown.Item text={'Add ' + xl8('chargingStation')} 
                      icon={<ChargerIcon width={'20'} height={'20'} fill="#C1C5C8"/>}
                      onClick={(_) => this.onAddDevice(site, false, false)}
                      className="mobile-site-option"
                    />
                    <Dropdown.Item text={'Add ' + xl8('hub')} 
                      icon={<HubIcon width={'20'} height={'20'} fill="#C1C5C8" />}
                      onClick={(_) => this.onAddDevice(site, true, false)}
                      className="mobile-site-option"
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <Dropdown
                button
                floating
                labeled
                icon={<PlusIcon width="21" height="21"/>}
                text={xl8('addDevice')}
                className="pull-right btn icon
                    btn-secondary add-device-btn">
                <Dropdown.Menu>
                  <Dropdown.Item text={xl8('chargingStation')} 
                    icon={<ChargerIcon width={'24'} height={'24'} fill="#C1C5C8"/>}
                    onClick={(_) => this.onAddDevice(site, false, false)}
                  />
                  <Dropdown.Item text={xl8('hub')} 
                    icon={<HubIcon width={'24'} height={'24'} fill="#C1C5C8" />}
                    onClick={(_) => this.onAddDevice(site, true, false)}
                  />
                  {/* <Dropdown.Item text={xl8('import')} 
                    icon={<ImportIcon width={'20'} height={'20'} fill="#C1C5C8" />}
                    onClick={(_) => this.onImportDevices()}
                  /> */}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <h2 className="site-title">
              {site.name}
            </h2>
            {/* <h5 className="site-secondary-header">
              Charging Stations
            </h5> */}
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 clear"
            key={site.id}>
            <div className="device-location-drop-target"
              onDragOver={(event) => {
                this.onSiteDragOverOrEnter(event, site);
              }}
              onDragEnter={(event) => {
                this.onSiteDragOverOrEnter(event, site);
              }}
              onDragLeave={(event) => {
                this.onSiteDragLeaveExit(event, site);
              }}
              onDragExit={(event) => {
                this.onSiteDragLeaveExit(event, site);
              }}
              onDrop={(event) => {
                this.onSiteDrop(event, site);
              }}>
              {/* <h2 className="device-location-header text-truncate">
                  {site.name}
                </h2> */}
              <ul className="device-list">
                <div className="row device-item-gutter">
                  {devices.length 
                    ? devices.map((device) => {
                          return this.renderDevice(site, device);
                        })
                    : <div>
                        <div className="empty-container-overlay text-center">
                          <NoDevicesIcon width={'96'} height={'96'}/>
                          <h3 className="empty-state-header">
                            No devices Here 
                          </h3>
                          <div className="empty-state-subheader">
                            Click &quot;Add Device&quot; to get started
                          </div>
                        </div>
                      </div>
                    }
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // If not isVirtual, hubs will redirect to renderHub, and attempts
  // to render virtual devices will return null
  // If virtual, it will be rendered as a device
  private renderDevice(site: Site, device: Device,
    isVirtual?: boolean): ReactNode {
    // if ((device.virtualDeviceIndex <= 0) && 
    //     device.connectors.length && !isVirtual)
    //   return null;


    if (!device.connectors.length && isVirtual)
      return null;
      

    if ((device.virtualDeviceIndex <= 0) && device.isHub && !isVirtual)
      return this.renderHub(site, device);

    let connectorId = device.connectors[
      device.virtualDeviceIndex]?.portConnectorId;

    // Only look for connector-related reasons not
    // to show it if it has any connectors
    if (device.connectors.length) {
      if (device.isHub && !isVirtual)
        return null;

      if (!connectorId)
        return null;
        
      let firstGoodConnIndex = device.connectors.findIndex((conn) => {
        return !!conn.portConnectorId;
      });
      if (!device.isHub && device.virtualDeviceIndex !== firstGoodConnIndex)
        return null;
    }

    let powerData: RealtimePowerPortConnectorSample;
    let powerValue: number;
    if (this.state.realtimePower) {
      let deviceData =  this.state.realtimePower?.devices[device.id];
      let connectorId = deviceData && device.connectors[
        device.virtualDeviceIndex || 0]?.portConnectorId;
      let connectorData = deviceData && deviceData[connectorId];
      powerValue = parseFloat((connectorData?.power / 1000).toFixed(2));
      powerData = connectorData?.curr;
    }

    const popoverStyle: React.CSSProperties = { 
      width: "365px", 
      borderRadius: "3px",
      // border: "1px solid #DCE3EB"    
    };

    
    let lastStatus = formatDate(this.state.statusesResponse?.devices[
      device.id]?.[connectorId]?.timestamp ?? null);
    
    let combinedKey = device.id + ':' + (device.virtualDeviceIndex ?? 0);

    return (
      <div className="col-lg-6 col-md-12 col-sm-12" style={{
        display: 'inline-block'
      }} key={combinedKey}>
        <TransitionGroup className="inline">
              <CSSTransition
        key=""
        timeout={1500}
        classNames='fadeinout'>
        <SwychedPopover 
          visibleKey={combinedKey}
          content={(powerData &&
            <>
              <dl>
                <dt>
                  Last Update
                </dt>
                <dd>
                  {lastStatus}
                </dd>
              </dl>
              <dl>
                <dt>
                  Last Meter Value
                </dt>
                <dd>
                  {formatDate(powerData.timestamp)}
                </dd>
              </dl>
              <dl hidden={isDemo()}>
                <dt>
                  Last Session Start
                </dt>
                <dd>
                  <span className="beta-tag">
                    {xl8('comingSoon')}
                  </span>
                </dd>
              </dl>
              <dl>
                <dt>
                  Real-time Power
                </dt>
                <dd>
                  {powerValue} kW
                </dd>
              </dl>
              {/* <dl>
                <dt>
                  Error Code
                </dt>
                <dd>
                  No error
                </dd>
                <dd>
                  <span className="beta-tag" hidden={isDemo()}>{xl8('comingSoon')}</span>
                </dd>
              </dl> */}
            </>) || <div className="no-data">No meter data</div>
          } 
          placement="bottom" 
          trigger="click"
          overlayStyle={popoverStyle}
          visibleMap={this.state.expandedPower}
          onClick={SwychedPopover.makeUpdater(this, 'expandedPower')}
          dismissTarget={this}
          dismissKey="expandedPower"
          dismissCtor={() => new Set<number>()}
          >
            
          {/* <div className="swyched-tooltip-wrapper"> */}
            <li key={device.id + ':' + (device.virtualDeviceIndex || 0)}
              draggable={true}
              onDragStart={(event) => {
                this.onDeviceDragStart(event, device);
              }}>
              {this.renderDeviceActionMenus(site, device, isVirtual)}
              <span className="station-icon">
                <ChargerIcon width={'24'} height={'24'} fill="#175785" />
              </span>
              <span className="device-title text-truncate"
                title={virtualDeviceName(device, true)}>
                {virtualDeviceName(device)}
              </span>
              <p>{xl8e('chargerType_' + 
              (device.chargerType || ChargerType.ACL2Charger))}</p>
              <span className={this.connectorChargerClassName(
                device, "status-bubble")}>
                <span className="status-square-indicator"></span>
                {this.realStatus
                  ? this.connectorStatusText(device.status)
                  : 'Available'}
              </span>
              {/* {this.state.backgroundRefreshPending
                ? <span className="fa spinner device-refresh-spinner">
                  <i className="fa fa-circle-o-notch 
                    fa-spin swyched-spinner"></i>
                </span>
                : null} */}
              <ul className="connector-list">
                {this.renderConnectors(device)}
              </ul>
            </li>
          {/* </div> */}
        </SwychedPopover>
        </CSSTransition>
        </TransitionGroup>
      </div>
      
    );
  }

  private renderDeviceActionMenus(site: Site, device: Device,
    isVirtual: boolean): ReactNode {
    return (
      <div className="device-actions">
        {/* {this.renderDeviceCommandMenu(site, device)} */}
        {this.renderDeviceMenu(site, device, isVirtual)}
      </div>
    );
  }

  private renderDeviceCommandMenu(site: Site, device: Device) {

    return <Dropdown icon='feed'>
      <Dropdown.Menu className="left pointing" >
        <Dropdown.Item icon='play' text={xl8('startSession')}
          onClick={(event) => {
            this.onStartDevice(site, device);
          }}/>
          
        <Dropdown.Item icon='stop' text={xl8('stopSession')}
          onClick={(event) => {
            this.onStopDevice(site, device);
          }} />
        <Dropdown.Item 
        icon={<RestartIcon width="22" height="22" fill="#aaa" />}
        text={xl8('restartDevice')}
          onClick={(event) => {
            this.onRestartDevice(site, device);
          }} />
      </Dropdown.Menu>
    </Dropdown>;
  }


  private renderDeviceMenu(site: Site, device: Device, isVirtual: boolean) {
    
    return <Dropdown icon={<EllipsisIcon width="28" height="28" fill="#C1C5C8"/>} className="btn-action">
      <Dropdown.Menu className="left pointing">
        <Dropdown.Item 
          icon={<CogIcon width="21.5" height="21.5" fill="rgb(193, 197, 200)"/>}
          text={xl8('editSettings')}
          onClick={(event) => {
            this.onEditDevice(site, device, isVirtual);
          }} />
        <Dropdown.Divider hidden={(device.isHub && !isVirtual)} />

        <Dropdown.Item icon='play' text={xl8('startSession')}
            hidden={(device.isHub && !isVirtual)}
            // description={xl8('beta')}
            description={!isDemo() ? 'beta' : undefined}
          onClick={(event) => {
            this.onStartDevice(site, device);
          }} />
        <Dropdown.Item icon='stop' text={xl8('stopSession') }
            hidden={(device.isHub && !isVirtual)}
            description={!isDemo() ? 'beta' : undefined}
          onClick={(event) => {
            this.onStopDevice(site, device);
          }} />
        <Dropdown.Item 
            icon={<RestartIcon width="24" height="24" fill="#aaa" />}
            text={xl8('restartDevice')}
            hidden={(device.isHub && !isVirtual)}
            description={!isDemo() ? 'beta' : undefined}
          onClick={(event) => {
            this.onRestartDevice(site, device);
          }} />
        {/* <Dropdown.Item icon='copy' text={xl8('copyDevice')}
          onClick={(event) => {
            this.onCopyDevice(devices.length + 1, device);
          }} /> */}
        <Dropdown.Divider hidden={(device.isHub && !isVirtual)} />
        <Dropdown.Item 
          icon={<DeleteIcon width="24" height="24" fill="rgb(193, 197, 200)"/>}
          text={
          ((device.isHub && !isVirtual) 
          ? xl8('deleteHub') : xl8('deleteStation'))}
          onClick={(event) => {
            this.onDeleteDevice(site, device, isVirtual);
          }} />
      </Dropdown.Menu>
    </Dropdown>;
  }


  private renderHub(site: Site, device: Device): ReactNode {
    let devices = this.state.siteDevices.deviceListBySite[site.id];
    let index = devices.findIndex((candidate) => {
      return candidate.id === device.id;
    });
    let end = index;
    while (end < devices.length) {
      if (devices[end].id !== device.id)
        break;
      ++end;
    }

    return (
      <div className="device-hub row device-item-gutter"
          key={device.id + ':hub'}>
        {this.renderDeviceActionMenus(site, device, false)}
        <div className="device-hub-title">
          <span className="device-hub-title-icon">
            <HubIcon width={'36'} height={'36'} fill="#175785" />
          </span>
          <span className="hub-name">
            {device.name}
          </span>
          <div className="hub-type">
            EVBox Hub
          </div>
        </div>
        {devices.slice(index, end)
        .map((virtualDevice, index) => {
          if (!device.connectors.length ||
              device.connectors.length > index)
            return this.renderDevice(site, virtualDevice, true);
          return null;
        })}
      </div>
    );
  }

  private onDeviceDragStart(event: React.DragEvent<HTMLLIElement>,
    device: Device) {
    let payload = JSON.stringify({
      uuid: DevicesSheet.dragUuid,
      device
    });
    console.log('setting drag text to', payload);
    event.nativeEvent.dataTransfer.effectAllowed = 'move';
    event.nativeEvent.dataTransfer.setData(
      DevicesSheet.dragContentType, payload);
  }

  private onSiteDrop(event: React.DragEvent<HTMLDivElement>, site: Site) {
    let json = event.nativeEvent.dataTransfer.getData(
      DevicesSheet.dragContentType);
    this.toggleHoverClass(event, site, false);

    let data: DragPayload = null;
    try {
      data = (json && JSON.parse(json)) || null;
    } catch (err) {
      // Do nothing
      console.log('onDrop failed while parsing json');
    }
    if (data?.uuid !== DevicesSheet.dragUuid)
      return;
    if (data.device.locationId === site.id) {
      console.log('failed onDrop because own site');
      return;
    }
    console.log('good drop');
    event.nativeEvent.stopPropagation();
    event.nativeEvent.preventDefault();
    let movedDevice = {
      ...data.device,
      locationId: site.id
    };

    ModalConfirm.showIndirect({
      message: 'Are you sure you want to move ' +
        data.device.name + ' to ' +
        site.name + '?',
      title: 'Move Device',
      okCallback: () => {
        return patchDevice(this.state.organization.id,
          data.device.locationId, movedDevice)
          .then((updated) => {
            toastSuccess('Device moved to ' + site.name);
            this.updateDevices(true);
          }).catch((err) => {
            toastError(err.message);
            return null;
          });
      }
    }).then((result) => {
      if (result?.ok)
        this.updateDevices(true);
    });
  }

  toggleHoverClass(event: React.DragEvent<HTMLDivElement>, site: Site,
    hovering: boolean): void {
    let target = event.target as HTMLElement;

    let dropTarget = target.closest(
      '.device-location-drop-target') as HTMLElement;

    if (!dropTarget) {
      console.log('failed, drag over did not find container');
      return;
    }

    event.nativeEvent.dataTransfer.dropEffect = 'move';
    event.nativeEvent.preventDefault();

    dropTarget.classList.toggle(
      'device-location-drop-target-hovering', !!hovering);
  }

  private onSiteDragOverOrEnter(event: React.DragEvent<HTMLDivElement>,
    site: Site) {
    this.toggleHoverClass(event, site, true);
  }

  private onSiteDragLeaveExit(event: React.DragEvent<HTMLDivElement>, 
    site: Site) {
    this.toggleHoverClass(event, site, false);
  }

  private renderMultiplier(device: Device,
    index: number, multiplier: number): ReactNode {
    return (
      <li key={device.id + ':' + index} className="connector-detail">
        <div className="centered-v connector-multiplier">
          ×{multiplier}
        </div>
      </li>
    );
  }

  private static mergableConnectors(
    destination: Connector, source: Connector): boolean {
    return destination.type === source.type;// &&
    //destination.name === source.name;
  }

  private renderConnectors(device: Device): React.ReactNode {
    let multiplier = 1;

    let output: ReactNode[] = device.connectors
      .filter((connector, index) => {
        return !device.isHub ||
          typeof device.virtualDeviceIndex !== 'number' ||
          device.virtualDeviceIndex === index;
      })
      .filter((connector, index) => {
        return !connector.id || connector.portConnectorId;
      })
      .reduce((output, connector, index, connectors) => {
        if (index > 0 && DevicesSheet.mergableConnectors(
          connectors[index - 1], connector)) {
          ++multiplier;
          return output;
        }
        if (multiplier > 1) {
          output.push(this.renderMultiplier(device, index, multiplier));
          multiplier = 1;
        }
        output.push(
          <li className="connector-detail"
            key={connector.id}
            data-html={true}
            data-place="right"
            data-class="connector-tooltip">
            {/* {connector.name} */}
            <span className={this.iconClassFromConnector(connector)}>
              {this.svgIconFromConnector(connector)}
            </span>
            {/* <img src={this.imageFromConnector(connector)}
              className={this.iconClassFromConnector(connector)}
              alt={this.altTextFromConnector(connector)} /> */}
          </li>
        );

        return output;
      }, [] as ReactNode[]);

    if (multiplier !== 1)
      output.push(this.renderMultiplier(device, 
        device.connectors.length, multiplier));

    return output;
  }

  svgIconFromConnector(connector: Connector): ReactNode {
    switch (connector.type) {
      case ConnectorType.SAEJ1772:
        return <Type1Icon width={'36'} height={'36'} />;

      case ConnectorType.Type2:
        return <Type2Icon width={'36'} height={'36'} />;
      
      case ConnectorType.CCS:
        return <CCS1Icon width={'36'} height={'36'} />;

      case ConnectorType.CCS2:
        return <CCS2Icon width={'36'} height={'36'} />;

      case ConnectorType.CHAdeMO:
        return <CHAdeMOIcon width={'36'} height={'36'} />;

      default:
        // Image for unknown type
        return '';
    }
  }

  iconClassFromConnector(connector: Connector): string {
    let base = 'charger-icon icon-';

    switch (connector.type) {

      case ConnectorType.SAEJ1772:
        return base + '1772';

      case ConnectorType.Type2:
        return base + 'type2';

      case ConnectorType.CCS:
        return base + 'ccs';

      case ConnectorType.CCS2:
        return base + 'ccs2';

      case ConnectorType.CHAdeMO:
        return base + 'chademo';


      default:
        // Image for unknown type
        return '';
    }
  }

  altTextFromConnector(connector: Connector): string {
    return connector.type;
  }

  onCopySite(site: Site): Promise<void> {
    let organizationId = this.state.organization.id;
    let newSite: Site;
    return postSiteByOrganization(site.organizationId, {
      id: null,
      organizationId: site.organizationId,
      name: 'Copy of ' + site.name,
      address: site.address,
      billingCity: site.billingCity,
      billingContact: site.billingContact,
      billingCountry: site.billingCountry,
      billingPostal: site.billingPostal,
      billingRegion: site.billingRegion,
      accessControlGroups: site.accessControlGroups.slice(),
      notes: null,
      popul8_url: null

    }).then((response) => {
      newSite = response;
      return getDevicesBySite(organizationId, site.id);
    }).then((devices) => {
      let promises = sequentialPromiseMap(devices, (device) => {
        return postDeviceBySite(organizationId,
          newSite.id, device);
      });

      return Promise.all(promises);
    }).then((results) => {
      toastSuccess(newSite.name);
      this.updateDevices(true);
    }).catch((err) => {
      toastError(err.message);
    });
  }

  onDeleteSite(site: Site): void {
    let organizationId = this.state.organization.id;
    ModalFactory.withDialog(ModalConfirm, (confirmModal) => {
      return confirmModal.showDialog(
        xl8('areYouSureDelete', site.name),
        xl8('deleteSiteCommand'), xl8('deleteSiteCommand'))
        .then((result) => {
          if (!result || !result.ok)
            return;

          deleteSiteById(organizationId, site.id).then(() => {
            toastSuccess(xl8('deleteSiteSuccess', site.name));
            this.updateDevices(true);
          }).catch((err) => {
            toastError(xl8('deleteSiteFail', err.message));
          });
        });
    });
  }

  onEditSite(site: Site): void {
    ModalFactory.withDialog(ModalEditSite, (editSiteModal) => {
      return editSiteModal.showDialog(this.state.organization,
        site, xl8('siteSettings'),
        'Done')
        .then((result) => {
          if (!result)
            return;

          toastSuccess(xl8('siteUpdated', site.name));
          this.updateDevices(true);
        }).catch((err) => {
          toastError(xl8('siteUpdateFailed', err.message));
        });
    });
  }

  onCopyDevice(newId: number, device: Device): void {
    let organizationId = this.state.organization.id;
    postDeviceBySite(organizationId, device.locationId, {
      ...device,
      name: 'Copy of ' + device.name
    }).then((device) => {
      toastSuccess(xl8('copyDeviceSuccess'));
      this.updateDevices(true);
    }).catch((err) => {
      toastError(xl8('copyDeviceFail', err.message));
    });
  }

  onDeleteDevice(site: Site, device: Device, isVirtual: boolean): void {
    let organizationId = this.state.organization.id;
    ModalConfirm.showIndirect({
      message: xl8('areYouSureDelete', device.name),
      title: xl8('deleteStation'),
      okText: xl8('deleteDevice'),
      okCallback: () => {
        return deleteDeviceById(organizationId, site.id, device.id).then(() => {
          toastSuccess(xl8('deleteDeviceSuccess', device.name));
          return true;
        }).catch((err) => {
          toastError(xl8('deleteDeviceFail', err.message));
          return null;
        });
      }
    }).then((result) => {
      if (result?.ok)
        this.updateDevices(true);
    });
  }

  onEditDevice(site: Site, device: Device, isVirtual: boolean): void {
    ModalFactory.withDialog(ModalEditDevice, (editDeviceModal) => {
      console.assert(this.props.organization);
      console.assert(device);
      return editDeviceModal.showDialog(
        this.props.organization, device, isVirtual)
        .then((result) => {
          if (!result)
            return;

          this.updateDevices(true);
        });
    });
  }

  onRestartDevice(site: Site, device: Device): void {
    restartDeviceById(this.state.organization.id,
      site.id, device.id).then((message) => {
        if (message.indexOf('Error: ') < 0)
          toastSuccess(message);
        else
          toastError(message);
      });
  }

  onCheckSession(site: Site, device: Device): void {

  }

  private activeSessionsKey(site: Site, device: Device): string {
    return site.id + ':' + device.id;
  }

  onStartDevice(site: Site, device: Device): void {
    let organizationId = this.state.organization.id;
    ModalFactory.withDialog(ModalStartSession, (startSessionModal) => {
      return startSessionModal.showDialog(organizationId, site, device)
        .then((result) => {
          if (!result)
            return;
        });
    });
  }

  onStopDevice(site: Site, device: Device): void {
    let organizationId = this.state.organization.id;

    ModalFactory.withDialog(ModalConfirm, (confirmModal) => {
      return confirmModal.showDialog(
          'Are you sure you want to stop session?',
          'Stop Session', 'Stop Session')
      .then((result) => {
        if (!result || !result.ok)
          return;

        // Get the transaction_id for this qu
        checkSession(organizationId, site, device)
        .then((check) => {
          if ('started' in check && check.started) {
            let successResponse = check as CheckSessionExistsResponse;

            return successResponse.transaction_id;
          } else {
            let failResponse = check as CheckSessionNotExistsResponse;

            throw new Error(JSON.stringify(failResponse));
          }        
        }).then((transactionId) => {
          return stopSession(organizationId,
            site, device, transactionId);
        }).then((response) => {
          toastSuccess(response.message);
        }).catch((err) => {
          toastError(JSON.stringify(err));
        });
      });
    });
  }

  private connectorStatusText(
    status: ConnectorStatus | DeviceStatusString): string {
    return xl8e('deviceStatus_' + (status || 'Unknown'));
  }

  private connectorChargerClassName(
    device: Device, incomingClasses: string): string {
    let statusClass: string;

    switch (device.status) {
      case ConnectorStatus.Available:
        statusClass = 'status-available';
        break;


      case ConnectorStatus.Finishing:
        statusClass = 'status-finishing';
        break;
      
      case ConnectorStatus.Preparing:
        statusClass = 'status-preparing';
        break;

      case ConnectorStatus.SuspendedEV:
      case ConnectorStatus.SuspendedEVSE:
        statusClass = 'status-suspended';
        break;

      case ConnectorStatus.Charging:
        statusClass = 'status-charging';
        break;

      case ConnectorStatus.Reserved:
        statusClass = 'status-reserved';
        break;

      case ConnectorStatus.Unavailable:
        statusClass = 'status-unknown';
        break;

      case ConnectorStatus.Faulted:
        statusClass = 'status-faulted';
        break;

      default:
      case ConnectorStatus.Unknown:
        statusClass = 'status-unknown';
        break;
    }

    return statusClass + ' ' + incomingClasses;
  }

  private onImportDevices(): Promise<void> {
    return ModalFactory.withDialog(ModalImportDevices, (modal) => {
      return modal.showDialog(this.state.organization.id);
    }).then((result) => {
      if (result?.devicesWereAdded)
        this.updateDevices(true);
    }).catch((err) => {
      toastError(err.message);
    });
  }

  private onAddSite(): Promise<Site | null> {
    let organizationId = this.state.organization.id;

    return ModalFactory.withDialog(ModalEditSite, (editSiteModal) => {
      return editSiteModal.showDialog(this.state.organization,
        ModalEditSite.newSite(organizationId),
        'Create Site', 'Done')
        .then((result) => {
          if (!result)
            return null;

          return result.site;

        }).then((site) => {
          if (!site)
            return site;

          toastSuccess('Created New Site "' + site.name + '"');
          this.updateDevices(true);

          return site;
        }).catch((err) => {
          toastError(err.message);
          return null;
        });
    });
  }

  private onAddDevice(site: Site,
    isHub: boolean, isVirtual: boolean): Promise<Device | null> {
    let organizationId = this.state.organization.id;

    let device = ModalEditDevice.newDevice(
      organizationId, site.id, isHub);

    return ModalFactory.withDialog(ModalEditDevice, (editDeviceModal) => {
      return editDeviceModal.showDialog(
        this.props.organization, device, isVirtual)
        .then((result) => {
          if (!result || !result.device)
            return null;

          this.updateDevices(true);
        }).catch((err) => {
          toastError(err.message);
          return null;
        });
    });
  }

  private renderSiteList(): ReactNode {
    if (!this.state.siteDevices.sites) {
      return (
        <div>
          <SwychedSpinner busy={1} />
        </div>
      );
    }

    // Build site selection array
    let allOption = {
      id: 0,
      name: xl8('allSites'),
      site: null
    };

    let siteOptions: SiteInfo[] = this.state.siteDevices.sites
      .map((site) => {
        return {
          id: site.id,
          name: site.name,
          site: site          
        };
      });

    //let siteFilterOptions = [allOption].concat(siteOptions);
    let siteFilterOptions = siteOptions.slice();

    return (
      <div className="left-side-bar-filter">
        <ul>

          {this.renderSiteFilterOption(allOption)}

          <hr className="list-separator" />

          {siteFilterOptions.map((siteInfo) => {
            return this.renderSiteFilterOption(siteInfo);
          })
          }
          <hr className="list-separator" />
          <li className="left-sidebar-add-new"
            onClick={(event) => {
              this.onAddSite();
            }}>
            <PlusIcon width="24" height="24" />
            {xl8('addSite')}
          </li>
        </ul>
      </div>
    );
  }

  private siteHasAnyFault(siteInfo: SiteInfo): boolean {
    if (siteInfo.id === 0)
      return false;

    return this.state.siteDevices?.deviceListBySite[siteInfo.id]
    ?.some((device) => {
      return device.status === 'Faulted';
    }) ?? false;
  }

  private renderSiteFilterOption(siteInfo: SiteInfo) {
    return (
      <li key={siteInfo.id}
        className={this.state.selectedSite?.id === siteInfo.site?.id
          ? ' selected' : ''}
        onClick={(_) => {
          this.setState({
            selectedSite: siteInfo.site
          });
        }}>
        <span className="sidebar-list-item text-truncate">
          {siteInfo.name}
        </span>
        {this.siteHasAnyFault(siteInfo)
          ? <span className="faulted-icon"> 
              <AlertIcon width="24" height="24" fill="#EF553F" />
            </span>
          : null
        }
      </li>
    );
  }

  private renderSiteSelect(): ReactNode {
    if (!this.state.siteDevices.sites) {
      return (
        <div>
          <SwychedSpinner busy={1} />
        </div>
      );
    }

    // Build site selection array
    let allOption = {
      id: 0,
      name: xl8('allSites'),
      site: null
    };

    let siteOptions = this.state.siteDevices.sites
      .map((site) => {
        return {
          id: site.id,
          name: site.name,
          site: site
        };
      });

    let siteFilterOptions = [allOption].concat(siteOptions);

    return (
      <select className="form-select"
        value={this.state.selectedSite?.id || 0}
        onChange={(event) => {
          this.setState({
            selectedSite: this.state.siteDevices.sites.find((site) => {
              return site.id === +event.target.value;
            })
          });
        }}>
        {
          siteFilterOptions.map((siteInfo) => {
            return (
              <option key={siteInfo.id} value={siteInfo.id}>
                {siteInfo.name}
              </option>
            );
          })
        }
      </select>
    );
  }
}

